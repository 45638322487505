import React, { useState, useEffect } from 'react'
import dayjs from 'dayjs'
import debug from 'debug'
import { Table } from 'semantic-ui-react'
import { selectors as refreshSelectors } from 'AppSrc/refresh/reducer'
import { checkinRequest, selectors as checkinSelectors } from 'AppSrc/checkin/reducer'
import { getCheckedInStatus, getCheckinMarker, getStateFromProps } from './helpers'
import { useAppDispatch, useAppSelector } from 'AppSrc/store'
import './style.css'

debug.enable('attendance/index:*')
// const log = debug('attendance/index:log')
// const info = debug('attendance/index:info')
// const error = debug('attendance/index:error')

type Props = {
  reg: RegistrantType
  name: string
  regItemKey: string
}

const Attendance = ({ reg, name, regItemKey }: Props) => {
  const dispatch = useAppDispatch()

  const allItems = useAppSelector(state => refreshSelectors.allItems(state))
  const settings = useAppSelector(state => refreshSelectors.settings(state))
  const checkinStatus = useAppSelector(state => checkinSelectors.checkinStatus(state))

  const [weekNumbers, setWeekNumbers] = useState<Array<number> | undefined>(undefined)
  const [weekNumber, setWeekNumber] = useState<number | undefined>(undefined)
  const [checkinKeys, setCheckinKeys] = useState<Array<CheckinKeyType> | undefined>(undefined)

  useEffect(() => {
    const {
      weekNumbers: newWeekNumbers,
      weekNumber: newWeekNumber,
      checkinKeys: newCheckinKeys,
    } = getStateFromProps({ reg, regItemKey, allItems })

    setWeekNumbers(newWeekNumbers)
    setWeekNumber(newWeekNumber)
    setCheckinKeys(newCheckinKeys)
  }, [reg, regItemKey, allItems])

  const getIdx = (week: number) => `${reg.id} / ${name} / ${regItemKey} / ${week}`

  const onClick = (week: number) => {
    const status = getCheckedInStatus(reg.checkins, checkinKeys!, week, weekNumber!)

    const idx = getIdx(week)
    const checkins = reg.checkins
    const item = reg.items[regItemKey]
    const checkinItems = { [regItemKey]: item }
    const checkinUndo = status === 'unknown' || status === 'late'
    const weekCheckinKey = item.checkin_keys[Math.min(item.checkin_keys.length, week) - 1]
    const checkinKeyDate = weekCheckinKey.replace(/^.* (\S+ \S+) week\d+$/, '$1')

    let checkinDate: string | undefined
    if (status === 'okay') {
      checkinDate = dayjs(checkinKeyDate).add(10, 'm').format('YYYY-MM-DD HH:mm:ss')
    } else if (status === 'missed') {
      checkinDate = dayjs(checkinKeyDate).subtract(10, 'm').format('YYYY-MM-DD HH:mm:ss')
    }

    // log('checkinRequest', idx, reg.id, { checkins, checkinItems, checkinUndo, checkinKey: weekCheckinKey, checkinDate })
    dispatch(
      checkinRequest(idx, reg.id, {
        checkinUndo,
        settings,
        checkins,
        checkinItems,
        checkinKey: weekCheckinKey,
        checkinDate,
        clearSearchOnCheckIn: false, // do not clear search when doing checkins through the attendance buttons
      })
    )
  }

  return (
    <Table
      celled
      structured
      collapsing
      compact
      singleLine
      unstackable
      textAlign="center"
      className="attendance-table"
    >
      <Table.Body>
        <Table.Row>
          {(weekNumbers || []).map(week => (
            <Table.Cell
              selectable
              key={week}
              width="1"
              disabled={week > weekNumber!}
              className="attendance-markers"
            >
              <button
                type="button"
                onClick={() => onClick(week)}
                tabIndex={week === weekNumber ? 0 : -1}
              >
                {getCheckinMarker(
                  checkinStatus[getIdx(week)],
                  getCheckedInStatus(reg.checkins!, checkinKeys!, week, weekNumber!)
                ) || <span className="week-numbers">{week}</span>}
              </button>
            </Table.Cell>
          ))}
        </Table.Row>
      </Table.Body>
    </Table>
  )
}

// Attendance.propTypes = {
//   reg: PropTypes.objectOf(() => true).isRequired,
//   name: PropTypes.string.isRequired,
//   regItemKey: PropTypes.string.isRequired,
// }

export default Attendance
