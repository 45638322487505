import { AppDispatch } from 'AppSrc/store'
import dayjs from 'dayjs'
import debug from 'debug'
import { checkinRequest } from './reducer'

debug.enable('checkin/helpers:*')
// const log = debug('checkin/helpers:log')
// const info = debug('checkin/helpers:info')
const error = debug('checkin/helpers:error')

export const getDate = (date?: Date) => dayjs(date).format('YYYY-MM-DD HH:mm:ss')

export const isCheckedIn = (checkins: CheckinsType, checkinItems: RegistrantItemsType): boolean => {
  for (const val of Object.values(checkinItems)) {
    if (!checkins[val.checkin_key]) {
      return false
    }
  }

  return true
}

export const getCheckinData = ({
  checkinUndo,
  settings,
  checkins,
  checkinItems,
  checkinDate,
  checkinKey,
}: {
  checkinUndo: CheckinUndoType
  settings?: settingsType
  checkins: CheckinsType
  checkinItems: RegistrantItemsType
  checkinDate?: string
  checkinKey?: CheckinKeyType
}) => {
  const date = checkinDate || (checkinUndo ? '' : dayjs().format('YYYY-MM-DD HH:mm:ss'))
  // log('getCheckinData checkins', checkins)
  // log('getCheckinData checkinUndo', checkinUndo)

  const checkinDataPerOrderId: { [orderId: string]: { [checkinKey: string]: string } } = {}
  Object.values(checkinItems).forEach(item => {
    const orderId = item.id
    // const key = (checkinKey || item.checkin_key).replace(new RegExp(`^#${orderId} (\\S+).*$`), '$1')
    // log('itemsDeselected', key, settings.itemsDeselected[key])
    // if (settings.itemsDeselected[key]) return // skip checkin if item is deselected

    if (checkinKey) {
      // checkin key provided
      if (checkinKey.match(new RegExp(`^#${orderId} `))) {
        // make sure checkin key matches order ID
        checkinDataPerOrderId[orderId] || (checkinDataPerOrderId[orderId] = {})
        checkinDataPerOrderId[orderId][checkinKey] = date
      } else {
        error('getCheckinData: checkin key', checkinKey, 'does not match order ID', orderId)
      }
    } else if (item.checkin_key && (checkinUndo || !checkins[item.checkin_key])) {
      // do not overwrite an existing checkin unless it's an undo
      checkinDataPerOrderId[orderId] || (checkinDataPerOrderId[orderId] = {})
      checkinDataPerOrderId[orderId][item.checkin_key] = date
    }
  })

  const checkinData: Array<{ orderId: string; checkins: object }> = []
  Object.keys(checkinDataPerOrderId).forEach(orderId => {
    checkinData.push({ orderId, checkins: checkinDataPerOrderId[orderId] })
  })

  return checkinData
}

const getCheckinUndo = (content: string): boolean => !!content.match(/checked in|undo/i)

const getCheckinButtonContent = (checkedIn: boolean) =>
  checkedIn ? 'Undo check\u00A0in' : 'Check in'

export const getCheckinInfo = ({
  name,
  regId,
  checkins,
  checkinItems,
}: {
  name: string
  regId: number | string
  checkins: [CheckinsType]
  checkinItems: [RegistrantItemsType]
}) => {
  const localCheckins = checkins[0]
  const localCheckinItems: RegistrantItemsType = checkinItems[0]

  const checkedIn = isCheckedIn(localCheckins, localCheckinItems)

  const idx = `${regId} / ${name}`
  const content = getCheckinButtonContent(checkedIn)

  return { idx, content, checkedIn, localCheckins, localCheckinItems }
}

export const processCheckinRequest = ({
  name,
  regId,
  checkins,
  checkinItems,
  dispatch,
  settings,
}: {
  name: string
  regId: number | string
  checkins: [CheckinsType]
  checkinItems: [RegistrantItemsType]
  dispatch: AppDispatch
  settings: settingsType
}) => {
  const { idx, content, localCheckins, localCheckinItems } = getCheckinInfo({
    name,
    regId,
    checkins,
    checkinItems,
  })

  // log('onClickFunc settings.uiRegistrants', settings.uiRegistrants)
  // log('onClickFunc', idx, regId, localCheckins, localCheckinItems, getCheckinUndo(content))

  return dispatch(
    checkinRequest(idx, regId, {
      checkinUndo: getCheckinUndo(content),
      settings,
      checkins: localCheckins,
      checkinItems: localCheckinItems,
      clearSearchOnCheckIn: settings.uiRegistrants.clearSearchOnCheckIn ?? false,
    })
  )
}
