import React, { useState, useEffect } from 'react'
import debug from 'debug'
import { formatDuration } from 'AppSrc/components/items/helpers'

debug.enable('countdown:*')
// const log = debug('countdown:log')
// const info = debug('countdown:info')
// const error = debug('countdown:error')

type Props = {
  updateFunc: () => ReturnType<typeof formatDuration>
  started: string
  startsIn: string
  startedSuffix: string
  interval?: number
}

const CountdownTimer = ({
  updateFunc,
  started,
  startsIn,
  startedSuffix,
  interval = 1000,
}: Props) => {
  const [value, setValue] = useState<string | null>(null)
  const [neg, setNeg] = useState<boolean | null>(null)
  let timer: NodeJS.Timeout | undefined

  const tick = () => {
    const { value: newValue, neg: newNeg } = updateFunc()
    setValue(newValue)
    setNeg(newNeg)
  }

  const startTimer = () => {
    tick()
    timer && clearInterval(timer)
    timer = setInterval(tick, interval)
  }

  const stopTimer = () => {
    clearInterval(timer)
  }

  useEffect(() => {
    startTimer()
    return () => stopTimer()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <p style={{ fontWeight: 300 }}>{neg ? started : startsIn}</p>
      <p className="countdown-timer">
        {value}
        {neg ? ` ${startedSuffix}` : ''}
      </p>
    </div>
  )
}

export default CountdownTimer
