import React from 'react'
import debug from 'debug'
import { Button, ButtonProps, Icon, Popup } from 'semantic-ui-react'
import { selectors as refreshSelectors } from 'AppSrc/refresh/reducer'
import { metaDataRequest, selectors as metaDataSelectors } from './reducer'
import { MetaDataPayloadDataType } from './types'
import { isBirthdayThisMonth, getMetaDataIdx } from './helpers'
import { useAppDispatch, useAppSelector } from 'AppSrc/store'
import './style.css'

debug.enable('metadatabirthday/component:*')
const log = debug('metadatabirthday/component:log')
// const info = debug('metadatabirthday/component:info')
// const error = debug('metadatabirthday/component:error')

type Props = { regId: string }

const metaDataType = 'birthday'

const MetaDataBirthday = ({ regId }: Props) => {
  const dispatch = useAppDispatch()

  const metaDataStatus = useAppSelector(state => metaDataSelectors.metaDataStatus(state))
  // const metaDataDate = useAppSelector(state => metaDataSelectors.metaDataDate(state)),
  // const errorData = useAppSelector(state => metaDataSelectors.errorData(state)),
  const users: DbUsersType = useAppSelector(state => refreshSelectors.users(state))

  // log('userId', userId)
  if (!regId || regId === '' || regId === '0' || isNaN(Number(regId)) || !users[regId]) {
    // no corresponding user or registering a new user
    return null
  }

  const user = users[regId]
  // log('user', user)

  const onClickFunc = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    data: ButtonProps
  ) => {
    const idx = getMetaDataIdx(regId, metaDataType)
    const payloadData: MetaDataPayloadDataType = {
      user: {
        userId: Number(regId),
        // If verification was already done, this is a request to unset it.
        meta: {
          birthday_verified: user.birthday_needs_verification || false,
        },
      },
    }

    log('metaDataRequest', idx, payloadData)
    dispatch(metaDataRequest(idx, payloadData))
  }

  const components = []
  const metaData = user.meta

  if (metaData?.birthday_month_year && isBirthdayThisMonth(metaData.birthday_month_year)) {
    // display birthday cake icon
    components.push(<Icon key="birthday-cake" name="birthday" color="red" />)
  }

  if (!user.birthday_discount) {
    // skip if user does not qualify for discount
    return !components.length ? null : <div className="metadata">{components}</div>
  }

  const idx = getMetaDataIdx(regId, metaDataType)
  const metaDataUpdateStatus = metaDataStatus[idx]
  const loading = !!(metaDataUpdateStatus || '').match(/ing/) // FIXME /ing/

  if (!user.birthday_needs_verification) {
    // birthday is already verified, display gift icon, click means unverifying birthday
    components.push(
      <Popup
        key="id-card"
        hoverable
        flowing
        on={['click']}
        trigger={
          <Icon
            size="large"
            name="id card outline"
            color="blue"
            fitted
            loading={loading}
            className="icon-button id-card-icon"
          />
        }
        header="Eligible for discount"
        content={
          <div>
            <div>Do you want to cancel eligibility?</div>
            <div style={{ textAlign: 'center', marginTop: '.5em' }}>
              <Button size="large" onClick={onClickFunc} color="red" basic content="Yes, Cancel" />
            </div>
          </div>
        }
        offset={[-12, 0]}
        className="id-card-popup"
      />
    )

    return <div className="metadata">{components}</div>
  }

  if (metaData?.birthday_month_year) {
    // birthday needs verification, display birth month/year and button to verify it
    components.push(
      <Button
        key={`verify-${metaDataType}`}
        {...{
          basic: true,
          color: 'red',
          size: 'large',
          compact: true,
          onClick: onClickFunc,
          content: `Verify ${metaDataType} is ${metaData.birthday_month_year}`,
          loading,
        }}
      />
    )
  }

  return <div className="metadata">{components}</div>
}

export default MetaDataBirthday
