import React, { useState } from 'react'
import { Grid, Button, Icon } from 'semantic-ui-react'
import debug from 'debug'
import { selectors as authSelectors, authSignInRequest } from './reducer'
import { useAppDispatch, useAppSelector } from 'AppSrc/store'
import './style.css'
import PageLoader from 'AppSrc/components/pageloader'

debug.enable('auth/component:*')
// const log = debug('auth/component:log')
// const info = debug('auth/component:info')
// const error = debug('auth/component:error')

const Login = () => {
  const dispatch = useAppDispatch()
  const login = () => dispatch(authSignInRequest())

  const signOutOngoing = useAppSelector(state => authSelectors.signOutOngoing(state))
  const signOutDone = useAppSelector(state => authSelectors.signOutDone(state))
  const signInOngoing = useAppSelector(state => authSelectors.signInOngoing(state))

  const signOut = signOutOngoing || signOutDone
  const [loading, setLoading] = useState(!signOut)

  // stop loading when signing out to display login button
  if (loading && signOut) {
    setLoading(false)
  }

  return (
    <Grid
      textAlign="center"
      verticalAlign="middle"
      style={{ height: '100vh', alignItems: 'center' }}
    >
      {loading || signInOngoing ? (
        <PageLoader message={signInOngoing ? 'Authenticating...' : 'Loading...'} />
      ) : (
        <Button
          icon={
            <Icon
              {...{
                name: signInOngoing ? 'spinner' : 'sign in',
                loading: signInOngoing,
              }}
            />
          }
          {...{
            size: 'massive',
            content: 'Login',
            positive: true,
            onClick: login,
            className: 'login-button',
          }}
        />
      )}
    </Grid>
  )
}

export default Login
