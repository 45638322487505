import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import './semantic-ui/dist/semantic.min.css'
import './fonts/font.css'
import './index.css'

import React, { Suspense } from 'react'
import { createRoot, hydrateRoot } from 'react-dom/client'
import { history, store } from 'AppSrc/store'

import App from 'AppSrc/components/app'
// import 'semantic-ui-css/semantic.min.css'
// eslint-disable-next-line import/extensions,import/no-unresolved
import AppError from 'ServerSrc/AppError'
import { ErrorBoundary } from 'react-error-boundary'
import { Provider } from 'react-redux'
import { HistoryRouter as Router } from 'redux-first-history/rr6'
import Spinner from 'AppSrc/components/spinner'
/* eslint-disable import/no-import-module-exports */
import { polyfillLoader } from 'polyfill-io-feature-detection'

const AppWithContext = (
  <Suspense fallback={<Spinner />}>
    <ErrorBoundary FallbackComponent={AppError}>
      <Provider store={store} serverState={window.__PRELOADED_STATE__}>
        <Router history={history}>
          <App />
        </Router>
      </Provider>
    </ErrorBoundary>
  </Suspense>
)

const container = document.getElementById('root')

const main = () => {
  if (!container) throw new Error('Failed to find the root element')

  if (container.hasChildNodes() === true) {
    hydrateRoot(container, AppWithContext)
  } else {
    const root = createRoot(container)
    root.render(AppWithContext)
  }

  if (module.hot) {
    module.hot.accept()
  }
}

// This function load polyfills only if needed. By default it uses polyfill.io.
polyfillLoader({
  features: 'Object.values',
  onCompleted: main,
})
