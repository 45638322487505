import React from 'react'
import debug from 'debug'
import { Button, ButtonProps, Icon, Popup } from 'semantic-ui-react'
import { selectors as orderSelectors, orderUpdateRequest } from './reducer'
import { useAppDispatch, useAppSelector } from 'AppSrc/store'
import './style.css'

debug.enable('order/component:*')
const log = debug('order/component:log')
// const info = debug('order/component:info')
// const error = debug('order/component:error')

type Props = {
  reg: RegistrantType
  orderId: number
  hideWhenPaid?: boolean
}

const OrderStatus = ({ reg, orderId, hideWhenPaid = false }: Props) => {
  const dispatch = useAppDispatch()

  const orderUpdateStatus = useAppSelector(state => orderSelectors.orderUpdateStatus(state))

  const onClickFunc = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, data: ButtonProps) => {
    const idx = `orderstatus-${orderId}`
    const status = reg.order_status?.[orderId] === 'completed' ? 'processing' : 'completed'

    log('orderUpdateRequest', idx, reg.id, { orderId, order_status: status })
    dispatch(orderUpdateRequest(idx, reg.id as string, { orderId, order_status: status }))
  }

  const components = []
  const orderStatus = reg.order_status?.[orderId]
  const orderPayByCash = (reg.payment_method?.[orderId] || '').match(/cod/i)
  const multipleOrders = Object.keys(reg.order_status || []).length > 1
  const orderCompleted = !!orderStatus.match(/completed/i)

  if (((hideWhenPaid || !orderPayByCash) && orderCompleted) || orderStatus === 'new') {
    return null
  }

  const idx = `orderstatus-${orderId}`
  const loading = !!(orderUpdateStatus[idx] || '').match(/ing/) // FIXME /ing/

  if (orderCompleted) {
    const iconName = orderPayByCash ? 'money' : 'list'

    components.push(
      <Popup
        key="order-status"
        hoverable
        flowing
        on={['click']}
        trigger={
          <Icon
            size="large"
            name={iconName}
            color="green"
            fitted
            loading={loading}
            className="icon-button order-status-icon"
          />
        }
        header={`Order ${orderStatus.toLowerCase()}`}
        content={
          <div>
            <div>Do you want to change the order status to processing?</div>
            <div style={{ textAlign: 'center', marginTop: '.5em' }}>
              <Button
                size="large"
                onClick={onClickFunc}
                color="red"
                basic
                content="Yes, change to processing"
              />
            </div>
          </div>
        }
        offset={[-12, 0]}
        className="order-status-popup"
      />
    )
    return <div className="order-status">{components}</div>
  }

  const content = orderCompleted
    ? 'Change to processing'
    : `${multipleOrders ? `Order #${orderId}: mark as paid` : 'Mark as paid'}`

  components.push(
    <Button
      key="order-status-button"
      {...{
        basic: true,
        color: 'red',
        size: 'large',
        compact: true,
        onClick: onClickFunc,
        content,
        loading,
      }}
    />
  )

  return <div className="order-status">{components}</div>
}

export default OrderStatus
