// helpers.ts
//
// Helper functions
//

import type { NextFunction, Request, Response } from 'express'
import { decode as heDecode, encode as heEncode } from 'html-entities'

import type { AxiosError } from 'axios'
import { RedisCommandArgument } from '@node-redis/client/dist/lib/commands'
import config from '../config'
import debug from 'debug'
import striptags from 'striptags'

config.debug && debug.enable('helpers:*')
const log = debug('helpers:log')
const info = debug('helpers:info')
const error = debug('helpers:error')

const redisDone = {
  db: false,
  dequeue: false,
  enqueue: false,
}
let serverStarted = false
let initDone = false

const defaultExports = {
  zeroPad: (num: number, places: number, pad = '0') => String(num).padStart(places, pad),

  getDateTime: (): string => {
    const now = new Date()
    const date = `${now.getFullYear()}-${defaultExports.zeroPad(
      now.getMonth() + 1,
      2
    )}-${defaultExports.zeroPad(now.getDate(), 2)}`
    const time = `${defaultExports.zeroPad(now.getHours(), 2)}:${defaultExports.zeroPad(
      now.getMinutes(),
      2
    )}:${defaultExports.zeroPad(now.getSeconds(), 2)}`
    return `${date} ${time}`
  },

  // TBD: This may not work for class series.
  getSlugSimple: (slug: string, startDate?: string | undefined) => {
    if (startDate) {
      // add start date suffix if missing
      const re = new RegExp(startDate)
      const sfx = `-${startDate}`
      if (!slug.match(re)) {
        // eslint-disable-next-line no-param-reassign
        slug += sfx
      }

      // Remove any suffix after the start date because TEC sometimes adds -<number>
      // suffixes to the event slug for recurring events.
      const re1 = new RegExp(`(${sfx}).*$`)
      // eslint-disable-next-line no-param-reassign
      slug = slug.replace(re1, '$1')
    }

    return slug
  },

  // massage slug for events and products
  // If we support events, use the event slug if available. Otherwise use the product slug.
  // Need to properly support recurring events.
  /** @deprecated */
  // eslint-disable-next-line default-param-last
  getSlug: (name: string, categories: Array<string> = [], startDate?: string | undefined) => {
    let slug = striptags(heDecode(name.toLowerCase())) // decode html, remove html tags

    slug = slug
      .replace(/(series|(taster|drop-?in) class|(DJ|Live Music) Dance Party).*$/i, '$1')
      .replace(/[–]+/g, '-')

    if (!slug.match(/class/i) && !categories.includes('class')) {
      log('... this is a dance', slug, categories)
      slug = slug
        .replace(/^.*(DJ Dance).*$/i, 'DJ Dance Party')
        .replace(/^.*(Live Music).*/i, 'Live Music Dance Party')
    }

    slug = slug.replace(/\s*\([^)]*\)/, '') // remove everything inside parenthesis
    slug = slug.replace(/[()]/g, '') // remove extra parenthesis

    slug = slug.replace(/\s*\[[^)]*\]/, '') // remove everything inside brackets
    slug = slug.replace(/[[\])]/g, '') // remove extra brackets

    // log('categories', categories)
    if (
      categories.includes('dj-dance') &&
      !slug.match(/DJ Dance Party/i) &&
      !categories.includes('class')
    ) {
      slug = 'DJ Dance Party'
    }
    if (
      categories.includes('live-music') &&
      !slug.match(/Live Music Dance Party/i) &&
      !categories.includes('class')
    ) {
      slug = 'Live Music Dance Party'
    }

    if (slug.match(/(taster|drop-?in) class|(DJ|Live Music) Dance Party/i) && startDate) {
      // add start date for drop-in/taster class, DJ Dance Party, or Live Music Dance Party
      slug += ` ${startDate}`
    }

    slug = slug.replace(/\s+/g, '-').replace(/[-]+/g, '-').toLowerCase()
    slug = slug.replace(/-\+/g, '') // remove plus signs

    return slug
  },

  getItemByEventSlug: (slug: string, items: DbItemsType): DbItemType | undefined => {
    for (const itemKey in items) {
      if (Object.prototype.hasOwnProperty.call(items, itemKey)) {
        const eventSlug = items[itemKey].event?.slug
        if (slug === eventSlug) {
          return items[itemKey]
        }
      }
    }

    return undefined
  },

  getItemByProductId: (productId: number, items: DbItemsType): DbItemType | undefined => {
    for (const itemKey in items) {
      if (Object.prototype.hasOwnProperty.call(items, itemKey)) {
        const eventProductsInfo = items[itemKey].event?.event_products_info
        if (eventProductsInfo) {
          for (const eventProductInfo of eventProductsInfo) {
            if (eventProductInfo.product_id === productId) {
              return items[itemKey]
            }
          }
        }
      }
    }

    return undefined
  },

  queryHtmlSafe: (
    obj: Record<string, any> | Array<any> | string | undefined
  ): object | Array<Record<string, unknown>> | string => {
    if (Array.isArray(obj)) {
      const safeObj = [] as Array<Record<string, any> | Array<any> | string | undefined>
      obj.forEach((val, idx) => {
        safeObj[idx] = defaultExports.queryHtmlSafe(val)
      })
      return safeObj
    }

    if (typeof obj === 'object') {
      const safeObj = {} as Record<string, any>
      Object.entries(obj).forEach(([subkey, val]) => {
        safeObj[subkey] = defaultExports.queryHtmlSafe(val)
      })
      return safeObj
    }

    return heEncode(obj)
  },

  getProductVariants: (
    product: DbItemProductType,
    variationId: string,
    variantNames: Array<string>
  ) => {
    const variants: Array<string> = []
    const variantRegex = new RegExp(variantNames.join('|'), 'i')

    if (variationId) {
      // variable product
      const attributes = product.product_variations?.[variationId].attributes
      attributes?.forEach(attr => {
        if (attr.name.match(variantRegex)) {
          variants.push(attr.option.toLowerCase())
        }
      })
    } else if (product.attributes) {
      // simple product
      product.attributes.forEach(attr => {
        if (attr.name.match(variantRegex)) {
          variants.push(attr.options[0].toLowerCase())
        }
      })
    }

    return variants
  },

  arrayFlatten: (arr: Array<any>): Array<any> =>
    arr.reduce(
      (acc, val) => acc.concat(Array.isArray(val) ? defaultExports.arrayFlatten(val) : val),
      []
    ),

  emptyObject: (obj: Record<string, any>): void =>
    Object.keys(obj).forEach(key => {
      delete obj[key] // eslint-disable-line no-param-reassign
    }),

  getValidProductsById: (items: DbItemsType) => {
    const validProductsById = {} as { [id: string]: 1 }

    Object.entries(items).forEach(([_itemKey, item]) => {
      // if we do not have event, select items with product
      // otherwise select items with event and product
      if (item.product && (!config.hasEvents || (item.event && item.product))) {
        validProductsById[item.product.id] = 1
      }
    })

    return validProductsById
  },

  serialize: (queryObj: wcQueryType | undefined, prefix = '', safeEncode = false) => {
    if (queryObj) {
      const encode = (x: string): string => (safeEncode ? encodeURIComponent(x) : x)
      const queryString = Object.entries(queryObj)
        .map(([k, val]) => `${encode(k)}=${encode((val ?? '').toString())}`)
        .join('&')
      return queryString ? `${prefix}${queryString}` : ''
    }

    return ''
  },

  getFilteredRegistrant: (
    regId: RegistrantType['id'],
    registrants: RegistrantsType,
    filterByItems = 'false',
    validProductsById: { [id: string]: 1 } = {}
  ) => {
    if (!defaultExports.objHasKey(registrants, regId.toString())) {
      return undefined
    }

    if (typeof filterByItems === 'boolean') {
      const err = `getFilteredRegistrant: filterByItems is a boolean = ${
        filterByItems ? 'true' : 'false'
      }, string required`
      throw err
    }

    if (filterByItems !== 'true') {
      return registrants[regId]
    }

    const reg = JSON.parse(JSON.stringify(registrants[regId])) // deep copy

    // get valid order IDs
    const validOrderIds = {} as { [id: string]: 1 }
    Object.keys(reg.items).forEach(itemKey => {
      const item = reg.items[itemKey]
      if (defaultExports.objHasKey(validProductsById, item.item_product_id)) {
        // log('validOrderIds', item.id)
        validOrderIds[item.id] = 1
        item.valid_product = 1 // FIXME: is it a good idea to modify line items here?
      }
    })

    // remove items from non-valid orders
    Object.keys(reg.items).forEach(itemKey => {
      const orderId = reg.items[itemKey].id
      if (!defaultExports.objHasKey(validOrderIds, orderId)) {
        // if (!(orderId in validOrderIds)) {
        // log('delete reg.items', itemKey)
        delete reg.items[itemKey]
      }
    })

    // remove data related to non-valid orders
    if (reg.total) {
      Object.keys(reg.total).forEach(orderId => {
        if (!defaultExports.objHasKey(validOrderIds, orderId)) {
          // if (!(orderId in validOrderIds)) {
          delete reg.total[orderId]
          delete reg.date_paid[orderId]
          delete reg.order_notes[orderId]
          delete reg.customer_note[orderId]
        }
      })
    }

    reg.valid_orders = Object.keys(validOrderIds) // FIXME: is it a good idea to modify reg here?

    if (Object.keys(reg.items).length) {
      return reg
    }

    return undefined
  },

  nameCapitalize: (name: string): string =>
    name
      .split(' ')
      .map(word => {
        if (word.match(/^(de|von)$/i)) return word.toLowerCase()
        if (word.toUpperCase() === word || word.toLowerCase() === word) {
          return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        }
        return word
      })
      .join(' '),

  redisRestoreObj: (result: Record<string, any>, hash: Record<string, any>): void => {
    if (result) {
      Object.entries(result).forEach(([key, value]) => {
        try {
          hash[key] = JSON.parse(value) // eslint-disable-line no-param-reassign
        } catch (err) {
          hash[key] = value // eslint-disable-line no-param-reassign
        }
      })
    }
  },

  redisRestoreHash: (
    result: Record<string, any>,
    hash: Record<string, any>,
    { merge, override }: { merge?: boolean; override?: Record<string, any> } = {}
  ) => {
    if (result) {
      const savedHash = JSON.parse(JSON.stringify(hash))

      Object.keys(result).forEach(key => {
        try {
          hash[key] = JSON.parse(result[key]) // eslint-disable-line no-param-reassign
        } catch (err) {
          // error(result[key], err)
        }
      })

      if (merge) {
        // merge settings that may not be in redis yet
        Object.keys(savedHash).forEach(key => {
          hash[key] || (hash[key] = savedHash[key]) // eslint-disable-line no-param-reassign
          Object.keys(savedHash[key]).forEach(subKey => {
            defaultExports.objHasKey(hash[key], subKey) ||
              (hash[key][subKey] = savedHash[key][subKey]) // eslint-disable-line no-param-reassign
          })
        })
      }

      if (override) {
        Object.keys(override).forEach(key => {
          Object.keys(override[key]).forEach(subKey => {
            hash[key][subKey] = override[key][subKey] // eslint-disable-line no-param-reassign
          })
        })
      }
    }
  },

  getRedisKey: (key: string): RedisCommandArgument => `${config.redis_prefix}${key}`,

  obscureEmail: (email: string) =>
    (email || '').replace(/^(.)[^@]*(.*)$/, '$1••••$2').toLowerCase(),

  objHasKey: <T>(obj: T, key: string | Array<string>): boolean =>
    (Array.isArray(key) ? key : [key]).reduce(
      (acc, k) =>
        acc ||
        Object.prototype.hasOwnProperty.call(obj, k) ||
        (typeof obj === 'object' && obj && k in obj && Object.getPrototypeOf(obj) === null),
      false
    ),

  lineItemMatchProductOrVariation: (
    item: wcItemToAddType,
    id: wcProductType['id'] | wcProductVariationType['id']
  ): boolean => (item.product_id === id && item.variation_id === 0) || item.variation_id === id,

  itemMatchProductOrVariation: (item: DbItemType, id: DbItemProductType['id']): boolean =>
    !!item.product && (item.product.id === id || item.product.variations.indexOf(id) >= 0),

  objValMayBeNumber: (
    obj: Record<string, any>,
    key: string,
    {
      isNumber,
      deepCopy,
      toLowerCase,
    }: { isNumber?: boolean; deepCopy?: boolean; toLowerCase?: boolean } = {
      isNumber: false,
      deepCopy: false,
      toLowerCase: false,
    }
  ): Record<string, any> => {
    if (defaultExports.objHasKey(obj, key)) {
      const objCopy = deepCopy ? JSON.parse(JSON.stringify(obj)) : { ...obj }

      if (isNumber ? true : !isNaN(Number(obj[key]))) {
        objCopy[key] = Number(objCopy[key])
        return objCopy
      }

      const val = obj[key]
      if (toLowerCase && typeof val === 'string' && val !== val.toLowerCase()) {
        objCopy[key] = objCopy[key].toLowerCase()
        return objCopy
      }
    }

    return obj // return unchanged object
  },

  setRedisDone: (fn: keyof typeof redisDone): void => {
    info(`Redis ${fn} is up.`)
    redisDone[fn] = true
  },

  isRedisDone: (): boolean => Object.values(redisDone).reduce((acc, val) => acc && val, true),

  setServerStarted: (): void => {
    info('Server is up.')
    serverStarted = true
  },

  setInitDone: (): void => {
    info('Init is done.')
    initDone = true
  },

  waitForRedisDone: (): Promise<void> =>
    new Promise(resolve => {
      const wait = () => {
        if (defaultExports.isRedisDone()) resolve()
        else setTimeout(wait, 100) // wait for 100s if redisDone is not set
      }
      setTimeout(wait, 0) // wait till all modules have been loaded
    }),

  waitForServerStarted: (): Promise<void> =>
    new Promise(resolve => {
      const wait = () => {
        if (serverStarted) resolve()
        else setTimeout(wait, 100) // wait for 100s if serverStarted is not set
      }
      setTimeout(wait, 0) // wait till all modules have been loaded
    }),

  waitForInitDone: (): Promise<void> =>
    new Promise(resolve => {
      const wait = () => {
        if (initDone) resolve()
        else setTimeout(wait, 100) // wait for 100s if initDone is not set
      }
      setTimeout(wait, 0) // wait till all modules have been loaded
    }),

  generateCouponCode: (): string => {
    let code = ''
    const possible = 'abcdefghijklmnopqrstuvwxyz123456789'

    for (let i = 0; i < 13; i++) {
      code += possible.charAt(Math.floor(Math.random() * possible.length))
    }

    return code
  },

  generateItemMetaId: (): string => {
    let id = ''
    const possible = '123456789'

    for (let i = 0; i < 5; i++) {
      id += possible.charAt(Math.floor(Math.random() * possible.length))
    }

    return id
  },

  emptyRedisObject: async (db: any, obj: Record<any, any>, objName: string): Promise<void> => {
    try {
      const objNamePx = defaultExports.getRedisKey(objName)
      await Promise.all(Object.keys(obj).map(async key => db.redisDbClient.HDEL(objNamePx, key)))
      await db.redisDbClient.DEL(objNamePx)
      defaultExports.emptyObject(obj)
    } catch (err) {
      error(err)
      throw new Error(err)
    }
  },

  getObjFromArrayById: (id: number, arr: Array<any>) => {
    for (let i = 0; i < arr.length; i += 1) {
      if (arr[i].id === id) return arr[i]
    }
    return {}
  },

  productImageUrl: (images: Array<wcProductImageType>): string => {
    if (images && images.length) {
      let url = images[0].src
      // add site url prefix if url is a relative path
      if (url.match(/^\//)) url = `${config.url}${url}`
      return url
    }

    return ''
  },

  // SATS 2020: fix attributes for T-Shirt style
  itemAttrsNameFix: (style: string): string => {
    if (style === 'Women') return 'Fitted Cut'
    if (style === 'Men') return 'Straight Cut'
    return style
  },

  fixItemName: (name: string): string =>
    name.replace(
      /(T-Shirt - )(Women|Men)/i,
      (match, p1, p2) => p1 + defaultExports.itemAttrsNameFix(p2)
    ),

  fixItemAttrs: (attrs: Array<RegistrantItemMetaDataType>): Array<RegistrantItemMetaDataType> =>
    attrs.map(attr => {
      // { id: 30464, key: 'style', value: 'Men' }
      if (attr.key === 'style' && typeof attr.value === 'string')
        return { ...attr, value: defaultExports.itemAttrsNameFix(attr.value) }
      return attr
    }),

  // Memoize async function until it's done
  //   Concurrent calls to an ongoing function will be returned the function promise
  //   and therefore will wait till it's done but won't rerun it.
  //   We delete the cache key on done so that the function can be rerun.
  //
  // See:
  //   https://github.com/danieldietrich/async-memoize/blob/master/src/index.ts
  //   https://bluepnume.medium.com/async-javascript-is-much-more-fun-when-you-spend-less-time-thinking-about-control-flow-8580ce9f73fc
  //
  asyncMemoizeUntilDone: <ARGS extends unknown[], RET>(
    asyncFn: (...args: ARGS) => Promise<RET>
  ) => {
    const cache: Record<string, Promise<RET>> = {}

    return async (
      { key: cacheKey, keyCheck }: { key: string | undefined; keyCheck: boolean },
      ...args: ARGS
    ) => {
      try {
        const key = cacheKey || JSON.stringify(args)
        if (keyCheck && !(key in cache)) return false
        cache[key] = cache[key] || asyncFn(...args).finally(() => delete cache[key])
        return cache[key]
      } catch (err) {
        throw new Error(err)
      }
    }
  },

  // Fix up old checkin key format (in place)
  fixUpOldCheckinKeyFormat: ({
    registrantCheckins,
    orderId,
    regItem,
    slugOfProductItem,
    items,
  }: {
    registrantCheckins: CheckinsType
    orderId: string
    regItem: RegistrantItemType
    slugOfProductItem: { [id: string]: string }
    items: DbItemsType
  }) => {
    const pid =
      regItem.item_variation_id && regItem.item_variation_id !== '0'
        ? regItem.item_variation_id
        : regItem.item_product_id
    const dbItemSlug = slugOfProductItem[pid]
    if (dbItemSlug) {
      // find matching slugs
      const matchingSlugs = [dbItemSlug]
      const matchingDbItem = items[dbItemSlug]
      if (matchingDbItem.event) {
        matchingSlugs.push(matchingDbItem.event.slug)
      }
      if (matchingDbItem.product) {
        matchingSlugs.push(matchingDbItem.product.slug)
        /** @deprecated: product.event_slug is deprecated */
        if (matchingDbItem.product.event_slug) matchingSlugs.push(matchingDbItem.product.event_slug)
      }

      // replace key with matching slug with new value
      for (const [key, value] of Object.entries(registrantCheckins)) {
        const possibleSlug = key.replace(new RegExp(`^#${orderId} (\\S+) .*$`), '$1')
        if (matchingSlugs.includes(possibleSlug)) {
          const newCheckinKey = key.replace(
            new RegExp(`^(#${orderId}) (\\S+) (.*)$`),
            `$1 ${regItem.item_id} $3`
          )

          log(`Fixing up old checkin key format "${key}" to "${newCheckinKey}"`)

          // eslint-disable-next-line no-param-reassign
          delete registrantCheckins[key]
          // eslint-disable-next-line no-param-reassign
          registrantCheckins[newCheckinKey] = value
        }
      }
    }
  },

  stripLinefeeds: (str: string) => str.replace('\n', ''),

  processWebhookOrder: async (
    req: Request,
    res: Response,
    next: NextFunction,
    type: string,
    db: any
  ): Promise<void> => {
    log('Received a webhook post request!!', type)
    const maybeOrder: Partial<wcOrderType> = req.body

    // log(webhookUri, maybeOrder)
    // skip orders without order id/status
    if (!maybeOrder.id || !maybeOrder.status) {
      log('... skipping order because missing order id or status', maybeOrder)
      res.sendStatus(200)
      return
    }

    const order: wcOrderType = req.body

    const registrantEmail = order.billing.email.toLowerCase()
    // FIXME: should there be an async function to get registrantsByEmail?
    const hasOrder = registrantEmail && db.registrantsByEmail[registrantEmail]?.orderIds[order.id]
    if (hasOrder) {
      log('... accepting order matching existing order')
    } else {
      // skip orders without accepted status
      if (!config.orders.statuses.includes(order.status)) {
        log('... skipping order because of skipped order status', order.status, order)
        res.sendStatus(200)
        return
      }
    }

    const registrantId = db.getOrderRegistrantId(order)

    const registrantInfo = {
      query: {
        reqId: 'server',
        filterByItems: 'true',
        // skip order if all line items have no corresponding loaded product items
        skipIfMissingAllItems: true,
      },
      id: registrantId,
      sendMsg: true,
    }

    const orderInfo = { id: order.id }

    await db.refreshRegistrants(registrantInfo, orderInfo, [order])
    res.sendStatus(200)
  },

  axiosErrorHandler: (err: AxiosError) => {
    if (err.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      error(err.response.data)
      // error(err.response.status)
    } else if (err.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      error(err.request)
    } else {
      // Something happened in setting up the request that triggered an Error
      error('Error', err.message)
    }
    // error(err.config)
  },

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  axiosAsyncWrap: async (fn: () => any, pn = (r: any) => r) => {
    try {
      const res = await fn()
      return pn(res)
    } catch (err) {
      defaultExports.axiosErrorHandler(err)
      throw new Error(err)
    }
  },
}

export default defaultExports
