// enums.ts

// FIXME: duplicated in index.d.ts

// eslint-disable-next-line no-shadow
export enum userRoleEnum {
  default = 'default',
  '24__under' = '24__under',
  senior = 'senior',
}

// eslint-disable-next-line no-shadow
export enum wcProductTaxonomyAttributeEnum {
  role = 'role',
  placement = 'placement',
  track = 'track',
}
