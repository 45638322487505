import { useState, useLayoutEffect } from 'react'
import debug from 'debug'
import { restoreScrollPosition, uiSaveScrollPosition, selectors as uiSelectors } from './reducer'
import { selectors as authSelectors } from 'AppSrc/auth/reducer'
import { store, useAppDispatch, useAppSelector } from 'AppSrc/store'
import { polyfill as smoothScrollPolyfill } from 'smoothscroll-polyfill'
import { Position } from './types'

debug.enable('ui/RestoreScrollPosition:*')
// const log = debug('ui/RestoreScrollPosition:log')
// const info = debug('ui/RestoreScrollPosition:info')
// const error = debug('ui/RestoreScrollPosition:error')

const isServer = typeof window === 'undefined'
if (!isServer) smoothScrollPolyfill()

const RestoreScrollPosition = () => {
  const dispatch = useAppDispatch()

  const scrollPosition = useAppSelector(state => uiSelectors.scrollPosition(state))

  const [pathname, setPathName] = useState('')

  useLayoutEffect(() => {
    const windowPathname = window.location.pathname
    // log('restore', windowPathname, scrollPosition[windowPathname])
    dispatch(restoreScrollPosition(windowPathname, scrollPosition[windowPathname]))
    // log('save pathname', windowPathname)
    setPathName(windowPathname)

    return () => {
      const scrollX = window.scrollX
      const scrollY = window.scrollY
      const position = { x: scrollX, y: scrollY } satisfies Position

      // log('saveScrollPosition', pathname, position)
      const authenticated = store ? authSelectors.authenticated(store.getState()) : false
      if (authenticated) dispatch(uiSaveScrollPosition(pathname, position))
    }
  }, [pathname])

  return null
}

export default RestoreScrollPosition
