import debug from 'debug'
import { Action, combineReducers, Reducer } from 'redux'
import { key as refreshKey, default as refreshReducer } from 'AppSrc/refresh/reducer'
import { key as checkinKey, default as checkinReducer } from 'AppSrc/checkin/reducer'
import { key as websocketKey, default as websocketReducer } from 'AppSrc/websocket/reducer'
import { key as authKey, default as authReducer } from 'AppSrc/auth/reducer'
import { key as uiKey, default as uiReducer } from 'AppSrc/ui/reducer'
import { key as searchKey, default as searchReducer } from 'AppSrc/search/reducer'
import { key as historyKey, default as historyReducer } from 'AppSrc/history/reducer'
import { key as orderUpdateKey, default as orderUpdateReducer } from 'AppSrc/order/reducer'
import { key as metaDataKey, default as metaDataReducer } from 'AppSrc/metadata/reducer'
import { key as topMenuKey, default as topMenuReducer } from 'AppSrc/components/topmenu/reducer'
import { authSignedOut } from 'AppSrc/auth/reducer'
import { RouterState } from 'redux-first-history'

debug.enable('store/rootReducer:*')
// const log = debug('store/rootReducer:log')
// const info = debug('store/rootReducer:info')
// const error = debug('store/rootReducer:error')

type reducersType = {
  router: Reducer<RouterState>
  [refreshKey]: typeof refreshReducer
  [checkinKey]: typeof checkinReducer
  [websocketKey]: typeof websocketReducer
  [authKey]: typeof authReducer
  [uiKey]: typeof uiReducer
  [searchKey]: typeof searchReducer
  [historyKey]: typeof historyReducer
  [orderUpdateKey]: typeof orderUpdateReducer
  [metaDataKey]: typeof metaDataReducer
  [topMenuKey]: typeof topMenuReducer
}

// export type reduxStateType = CombinedState<reducersType> | CombinedState<{ router: Reducer }>

const rootReducer = (routerReducer: Reducer<RouterState>) => {
  const reducers: reducersType = {
    router: routerReducer,
    [refreshKey]: refreshReducer,
    [checkinKey]: checkinReducer,
    [websocketKey]: websocketReducer,
    [authKey]: authReducer,
    [uiKey]: uiReducer,
    [searchKey]: searchReducer,
    [historyKey]: historyReducer,
    [orderUpdateKey]: orderUpdateReducer,
    [metaDataKey]: metaDataReducer,
    [topMenuKey]: topMenuReducer,
  }
  const appReducer = combineReducers(reducers)

  const authRootReducer = (state: ReturnType<typeof appReducer>, action: Action) => {
    // log('rootReducer', action)
    if (action.type === authSignedOut.type) {
      // on logout, reset everything except router
      return appReducer({ router: state.router }, action)
    }

    return appReducer(state, action)
  }

  return authRootReducer
}

export default rootReducer
