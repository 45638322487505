import { createLogic } from 'redux-logic'
import debug from 'debug'
import { httpRequestErrorMessage } from 'AppSrc/store/helpers'
import { RootState, Dependency } from 'AppSrc/store'
import {
  OrderActionTypes,
  orderUpdateFulfilled,
  orderUpdateRejected,
  orderUpdateRequest,
} from './reducer'
import { OrderMeta, OrderPayload } from './types'
import { AxiosError } from 'axios'

debug.enable('order/logic:*')
const log = debug('order/logic:log')
// const info = debug('order/logic:info')
// const error = debug('order/logic:error')

export const orderLogic = createLogic<
  RootState, // State
  OrderPayload, // Payload
  OrderMeta, // Meta
  Dependency, // Dependency
  OrderActionTypes // Context
>({
  type: orderUpdateRequest.type,

  async process({ httpClient, getState, action }, dispatch, done) {
    const idx = action.payload[0]
    const regId = action.payload[1] as string // registrant ID
    const orderUpdateData = action.payload[2]
    log('orderUpdateLogic', action.payload)

    try {
      const resp = await httpClient.put(
        `/api/registrants/${encodeURIComponent(btoa(regId))}?reqId=client&filterByItems=true`,
        orderUpdateData
      )
      log('orderUpdateLogic then', resp)
      dispatch(orderUpdateFulfilled(idx)) // FIXME? should we check the resp?
      if (orderUpdateData?.create) {
        if (`${regId}` !== '0' && !isNaN(Number(regId))) {
          window.location.assign(`/registrants/${encodeURIComponent(regId)}`)
        } else {
          window.location.assign('/registrants')
        }
      }
    } catch (err) {
      const errorMessage = httpRequestErrorMessage('orderUpdateLogic', err as AxiosError)
      dispatch(orderUpdateRejected(idx, errorMessage))
    } finally {
      done()
    }
  },
})

export default orderLogic
