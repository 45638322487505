import React, { useState, useEffect } from 'react'
import debug from 'debug'
import { Grid, Card, Header } from 'semantic-ui-react'
import { RestoreScrollPosition } from 'AppSrc/ui'
import { selectors as refreshSelectors } from 'AppSrc/refresh/reducer'
import { getItemUri } from 'AppSrc/components/registrants/helpers'
import { itemsSort, itemsIfEvent } from 'AppSrc/refresh/helpers'
import { initialItem } from 'AppSrc/refresh/reducer'
import PageLoader from 'AppSrc/components/pageloader'
import ItemCard from 'AppSrc/components/items/ItemCard'
import { useAppDispatch, useAppSelector } from 'AppSrc/store'
import 'AppSrc/components/items/style.css'
import { updateTopMenuContent } from 'AppSrc/components/topmenu/reducer'

debug.enable('stats/index:*')
// const log = debug('stats/index:log')
// const info = debug('stats/index:info')
// const error = debug('stats/index:error')

const Stats = () => {
  const dispatch = useAppDispatch()

  const items = useAppSelector(state => refreshSelectors.items(state))
  const settings = useAppSelector(state => refreshSelectors.settings(state))

  const [loadingExpired, setLoadingExpired] = useState<boolean>(false)

  const itemTopMenuName = 'Statistics'
  const pageLoadingDelay = 2000

  useEffect(() => {
    dispatch(updateTopMenuContent(<div className="top-menu-title">{itemTopMenuName}</div>))
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // do not stay in loading state more than 2 seconds
    const timeout = setTimeout(() => setLoadingExpired(true), pageLoadingDelay)

    return () => timeout && clearTimeout(timeout)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const itemsNotLoaded =
    Object.keys(items).length === 1 && Object.values(items)[0].slug === 'item-slug'

  if (!loadingExpired && itemsNotLoaded) {
    return (
      <Grid
        textAlign="center"
        verticalAlign="middle"
        style={{ height: '100vh', alignItems: 'center' }}
      >
        <PageLoader />
      </Grid>
    )
  }

  if (Object.keys(items).length === 0 || itemsNotLoaded) {
    return (
      <Grid
        textAlign="center"
        verticalAlign="middle"
        style={{ height: '100vh', alignItems: 'center' }}
      >
        <Header size="medium">{Object.values(initialItem)[0].event?.title}</Header>
      </Grid>
    )
  }

  const localItems = Object.keys(items).length === 0 ? initialItem : items
  // log(Object.values(localItems).filter(itemsIfEvent(settings)))

  return (
    <div>
      <RestoreScrollPosition />
      <Grid
        centered
        className="grid-container grid-statistics"
        padded="horizontally"
        style={{ marginTop: '7rem', marginBottom: '2rem' }}
      >
        <Grid.Row>
          <Grid.Column>
            <Card.Group itemsPerRow={2} stackable>
              {Object.values(localItems)
                .filter(itemsIfEvent(settings))
                .filter(item =>
                  item.event?.categories?.includes('dance')
                    ? settings.uiItems.itemsShowDanceInStats
                    : true
                )
                .sort(itemsSort)
                .map(item => (
                  <ItemCard
                    key={getItemUri(item)}
                    gridCelled="internally"
                    item={item}
                    shouldShowAttendance
                    truncateItemTitle={38}
                  />
                ))}
            </Card.Group>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  )
}

export default Stats
