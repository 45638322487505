export const getEventMetaDataDate = (metaData: wcItemMetaDataType) => {
  const date = metaData.find(data => data?.key === 'Date' || data?.key === 'Dates')
  if (!date) return null

  return `${date.key}: ${date.value}`
}

export const getEventMetaDataCheckin = (checkinsJson: string) => {
  // "{\"#347699 18759 2024-02-07 21:30:00 week1\":\"2024-02-07 22:41:29\"}"
  const checkins: { [key: string]: string } = JSON.parse(checkinsJson)

  const checkinsValues = Object.values(checkins)

  return (
    Object.entries(checkins)
      // remove duplicates
      .filter(([_key, value], index) => checkinsValues.indexOf(value) === index)
  )
}
