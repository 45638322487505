import debug from 'debug'
import { MetaDataPayloadDataType } from './types'
import { getDate } from './helpers'
import { anyToString } from 'AppSrc/store/helpers'
import { createSlice } from '@reduxjs/toolkit'

debug.enable('metadata/reducer:*')
// const log = debug('metadata/reducer:log')
// const info = debug('metadata/reducer:info')
// const error = debug('metadata/reducer:error')

export const key = 'metaData'

const initialState: {
  metaDataStatus: { [idx: string]: string }
  metaDataDate: { [idx: string]: string }
  errorData: { [idx: string]: string }
} = {
  metaDataStatus: {},
  metaDataDate: {},
  errorData: {},
}

const metaDataSlice = createSlice({
  name: key,
  initialState,
  reducers: {
    metaDataRequest: {
      reducer: (state, action: { payload: [string, MetaDataPayloadDataType] }) => {
        const idx = action.payload[0]
        return {
          ...state,
          metaDataStatus: { ...state.metaDataStatus, [idx]: 'updating metadata' },
          metaDataDate: { ...state.metaDataDate, [idx]: getDate() },
        }
      },
      prepare: (
        idx: string,
        data: MetaDataPayloadDataType
      ): { payload: [string, MetaDataPayloadDataType] } => ({ payload: [idx, data] }),
    },
    metaDataCancel: {
      reducer: (state, action: { payload: [string] }) => {
        const idx = action.payload[0]
        return {
          ...state,
          metaDataStatus: { ...state.metaDataStatus, [idx]: 'cancelled' },
          metaDataDate: { ...state.metaDataDate, [idx]: getDate() },
        }
      },
      prepare: (idx: string): { payload: [string] } => ({ payload: [idx] }),
    },
    metaDataFulfilled: {
      reducer: (state, action: { payload: [string] }) => {
        const idx = action.payload[0]
        return {
          ...state,
          metaDataStatus: { ...state.metaDataStatus, [idx]: 'metadata updated' },
          metaDataDate: { ...state.metaDataDate, [idx]: getDate() },
        }
      },
      prepare: (idx: string): { payload: [string] } => ({ payload: [idx] }),
    },
    metaDataRejected: {
      reducer: (state, action: { payload: [string, Error] }) => {
        const idx = action.payload[0]
        const err = action.payload[1]
        return {
          ...state,
          metaDataStatus: { ...state.metaDataStatus, [idx]: 'metadata rejected' },
          metaDataDate: { ...state.metaDataDate, [idx]: getDate() },
          errorData: { ...state.errorData, [idx]: anyToString(err) },
        }
      },
      prepare: (idx: string, err: Error): { payload: [string, Error] } => ({ payload: [idx, err] }),
    },
  },
  selectors: {
    metaDataStatus: state => state.metaDataStatus,
    metaDataDate: state => state.metaDataDate,
    errorData: state => state.errorData,
  },
})

export type MetaDataActionTypes = SliceActions<typeof metaDataSlice.actions>

export const selectors = metaDataSlice.selectors
export default metaDataSlice.reducer

export const { metaDataRequest, metaDataCancel, metaDataFulfilled, metaDataRejected } =
  metaDataSlice.actions
