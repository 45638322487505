export enum stateIndexes {
  REFRESH_IDX_BUTTON_REFRESH = 'settings refresh button', // refresh button
  REFRESH_IDX_BUTTON_REFRESH_TRACK = 'settings refresh button track', // refresh button track
  REFRESH_IDX_BUTTON_GET_DATA = 'settings get data', // get data button
  REFRESH_IDX_STORE_INIT = 'store init', // store init at app load
  REFRESH_IDX_MSG_RECEIVED = 'update message received', // update message received
  // items/registrants/users update on server, trigger corresponding refresh
  REFRESH_IDX_MSGREC_DISPATCH_REFRESH_REQUEST = 'message received dispatch refresh',
  REFRESH_IDX_ITEMS = 'items',
  REFRESH_IDX_REGISTRANTS = 'registrants',
  REFRESH_IDX_USERS = 'users',
}

export type stateIndexType = stateIndexes | `#${number | string}` | refreshEndpointFieldEndpointType

export type refreshTypes = 'refresh' | 'skipRefresh'

export type refreshEndpointFieldEndpointType =
  | stateIndexes.REFRESH_IDX_ITEMS
  | stateIndexes.REFRESH_IDX_REGISTRANTS
  | stateIndexes.REFRESH_IDX_USERS
  | 'refresh'
  | 'refresh/items'
  | 'refresh/registrants'
  | 'refresh/users'
  | `refresh/users/${number}`
  | `refresh/orders/${number | string}`
  | `refresh/orders`

export type refreshEndpointType = {
  endpoint?: refreshEndpointFieldEndpointType
  query?: 'filterByItems=true' | `search=${string}&page=${number}&per_page=${number}`
}

export const refreshEndpoints: Array<refreshEndpointType> = [
  { endpoint: stateIndexes.REFRESH_IDX_ITEMS },
  { endpoint: stateIndexes.REFRESH_IDX_REGISTRANTS, query: 'filterByItems=true' }, // only get registrants with matching items
  { endpoint: stateIndexes.REFRESH_IDX_USERS },
]

export type RefreshRequestPayload = [
  idx: stateIndexType,
  refreshType: refreshTypes,
  refreshSelectedEndpoints: refreshEndpointType[]
]

export type RefreshData = Array<
  DbUsersType | DbItemsType | RegistrantsType | wcOrderType[] | undefined
>
