import { createLogic } from 'redux-logic'
import debug from 'debug'
import {
  AuthActionTypes,
  selectors as authSelectors,
  authSignedOut,
  authSignInRejected,
  authSignInRequest,
  authSignOutRejected,
  authSignOutRequest,
} from './reducer'
import { RootState, Dependency } from 'AppSrc/store'
import { AuthPayload } from './types'

debug.enable('auth/logic:*')
const log = debug('auth/logic:log')
// const info = debug('auth/logic:info')
const error = debug('auth/logic:error')

export const signInLogic = createLogic<
  RootState, // State
  AuthPayload, // Payload
  any, // Meta
  Dependency, // Dependency
  AuthActionTypes // Context
>({
  type: authSignInRequest.type,
  // cancelType: authSignInCancel.type,
  // latest: true, // take latest only

  // use axios injected as httpClient from configureStore logic deps
  // we also have access to getState and action in the first argument
  // but they were not needed for this particular code
  async process({ httpClient, getState, action }, dispatch, done) {
    try {
      const { data: resp } = await httpClient.post(`/login${window.location.search}`, {
        originalUrlPath: window.location.pathname,
      })
      // log('signInLogic', resp.authUrl || 'no auth url provided')
      if (resp.authUrl) window.location.assign(resp.authUrl)
    } catch (err) {
      error(err) // might be a render err
      dispatch(authSignInRejected(err as Error))
    } finally {
      log('signInLogic done')
      done()
    }
  },
})

export const signOutLogic = createLogic<
  RootState, // State
  AuthPayload, // Payload
  any, // Meta
  Dependency, // Dependency
  AuthActionTypes // Context
>({
  type: authSignOutRequest.type,
  // cancelType: augthSignOutCancel.type,
  // latest: true, // take latest only

  // use axios injected as httpClient from configureStore logic deps
  // we also have access to getState and action in the first argument
  // but they were not needed for this particular code
  async process({ httpClient, getState, action }, dispatch, done) {
    try {
      // log('signOutLogic /logout')
      await httpClient.get('/logout')
      const authenticated = authSelectors.authenticated(getState())
      if (authenticated) dispatch(authSignedOut())
    } catch (err) {
      error(err) // might be a render err
      dispatch(authSignOutRejected(err as Error))
    } finally {
      log('signOutLogic done')
      done()
    }
  },
})

export default { signInLogic, signOutLogic }
