import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import debug from 'debug'
import { WsMsgRcvType, WsMsgRcvUnkownType, WsMsgSndType } from './types'
import { store } from 'AppSrc/store'
import {
  selectors as authSelectors,
  authSignedIn,
  authSignedOut,
  checkSession,
  signedInMsg,
} from 'AppSrc/auth/reducer'
import { isCancelledRequestId, setClientId } from './helpers'
import { refreshFulfilled, refreshRequest } from 'AppSrc/refresh/reducer'
import { refreshEndpoints, stateIndexes } from 'AppSrc/refresh/types'

debug.enable('websocket/reducer:*')
const log = debug('websocket/reducer:log')
const info = debug('websocket/reducer:info')
// const error = debug('websocket/reducer:error')

export const key = 'websocket'

const initialState = {
  wsConnected: false, // WS connected?
}

const wsSlice = createSlice({
  name: key,
  initialState,
  reducers: {
    wsConnect: (state, _action: PayloadAction<void>) => ({
      ...state,
      wsConnected: true,
    }),
    wsDisconnect: (state, _action: PayloadAction<void>) => ({
      ...state,
      wsConnected: false,
    }),
    wsMsgListen: state => state,
    wsMsgReceived: (state, _action: { payload: WsMsgRcvType | WsMsgRcvUnkownType }) => state,
    wsMsgSend: (state, _action: { payload: WsMsgSndType }) => state,
    wsMsgDiscarded: state => state,
    wsMsgStopListening: state => state,
    wsMsgError: state => state,
  },
  selectors: {
    wsConnected: state => state.wsConnected,
  },
})

// NOTE: dispatch action here based on received message content
// NOTE: we can stringify/parse the message here if needed
export const msgListen = () => wsMsgListen()

export const msgSend = (msg: WsMsgSndType) => {
  log('msgSend', msg)
  return wsMsgSend(msg)
}

export const msgReceived = (msg: WsMsgRcvType | WsMsgRcvUnkownType) => {
  log('msgReceived', msg)

  if (
    !('reqId' in msg) ||
    msg?.type === 'hot' ||
    msg?.type === 'liveReload' ||
    msg?.type === 'reconnect' ||
    msg?.type === 'hash' ||
    msg?.type === 'ok'
  ) {
    return wsMsgDiscarded()
  }

  const { reqId, clientId, cmd, data } = msg
  const cmdMatch = cmd.match(/^(?:no )?(items|registrants|users) updated$/)
  const authenticated = store ? authSelectors.authenticated(store.getState()) : false

  // discard message if its ID is part of the cancelled request IDs
  if (isCancelledRequestId(reqId)) {
    info(`Cancelling message with request ID ${reqId}`)
    return wsMsgDiscarded()
  }

  if (cmd === 'send first connect') {
    if (clientId) setClientId(clientId)
    const userInfo: UserInfoType = JSON.parse(localStorage.getItem('userInfo') || 'null')
    const sendMsg: WsMsgSndType = { cmd: 'first connect', data: { userInfo, authenticated } }
    return msgSend(sendMsg)
  }

  if (cmd === 'send session check') {
    return checkSession(authenticated)
  }

  if (cmd === 'logout') {
    return authSignedOut()
  }

  if (cmd === 'send get settings') {
    if (!authenticated) {
      log('skipping get settings when not authenticated')
      return wsMsgDiscarded()
    }

    return msgSend({ cmd: 'get settings', data })
  }

  if (cmd === 'send refresh request') {
    if (!authenticated) {
      log('skipping refresh request when not authenticated')
      return wsMsgDiscarded()
    }

    return refreshRequest(stateIndexes.REFRESH_IDX_MSGREC_DISPATCH_REFRESH_REQUEST, 'skipRefresh')
  }

  if (cmdMatch) {
    // items/registrants/users updated
    if (!authenticated) {
      log('skipping refresh request when not authenticated')
      return wsMsgDiscarded()
    }

    const refreshSelectedEndpoints = refreshEndpoints.filter(re => re.endpoint === cmdMatch[1])
    // log(`dispatching refresh request for ${refreshGroups}`)
    const nextAction = refreshRequest(
      stateIndexes.REFRESH_IDX_MSGREC_DISPATCH_REFRESH_REQUEST,
      'skipRefresh',
      refreshSelectedEndpoints
    )

    if (cmdMatch[1] === 'registrants') {
      store.dispatch(refreshFulfilled(stateIndexes.REFRESH_IDX_BUTTON_REFRESH_TRACK, [], []))
    }

    return nextAction
  }

  if (cmd === 'login') {
    const { userInfo } = data
    log('received login message', data)
    store.dispatch(authSignedIn(userInfo as UserInfoType))
    return signedInMsg()
  }

  // forward message to other reducers
  return wsMsgReceived(msg)
}

export type WsActionTypes = SliceActions<typeof wsSlice.actions>

export const selectors = wsSlice.selectors
export default wsSlice.reducer

export const {
  wsConnect,
  wsDisconnect,
  wsMsgListen,
  wsMsgReceived,
  wsMsgSend,
  wsMsgDiscarded,
  wsMsgStopListening,
  wsMsgError,
} = wsSlice.actions
