import refreshLogic from 'AppSrc/refresh/logic'
import checkinLogic from 'AppSrc/checkin/logic'
import websocketLogic from 'AppSrc/websocket/logic'
import { signInLogic, signOutLogic } from 'AppSrc/auth/logic'
import searchLogic from 'AppSrc/search/logic'
import orderLogic from 'AppSrc/order/logic'
import metaDataLogic from 'AppSrc/metadata/logic'

const logicArray = [
  refreshLogic,
  checkinLogic,
  websocketLogic,
  signInLogic,
  signOutLogic,
  searchLogic,
  orderLogic,
  metaDataLogic,
]

export default logicArray
