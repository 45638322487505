import debug from 'debug'
import React from 'react'
import config from './config'

config.debug && debug.enable('AppError:*')
// const log = debug('AppError:log')
// const info = debug('AppError:info')
// const error = debug('AppError:error')

type Props = {
  error: Error
}

const AppError = ({ error }: Props) => (
  <div>
    <h1>Application Error</h1>
    <pre style={{ whiteSpace: 'pre-wrap' }}>{error.stack}</pre>
  </div>
)

export default AppError
