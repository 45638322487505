import React from 'react'

type Props = { active?: boolean }

const Spinner = ({ active = true }: Props) => (
  <div
    className={['spinner', active && 'spinner--active'].join(' ')}
    role="progressbar"
    aria-label={active ? 'active' : 'inactive'}
    aria-busy={active ? 'true' : 'false'}
  />
)

export default Spinner
