import { createSlice } from '@reduxjs/toolkit'

export const key = 'history'

const initialState = {
  historyLastItemPathname: '/',
}

const historySlice = createSlice({
  name: key,
  initialState,
  reducers: {
    historySaveItemPathname: {
      reducer: (state, action: { payload: [Location['pathname']] }) => {
        return {
          ...state,
          historyLastItemPathname: action.payload[0],
        }
      },
      prepare: (pathname: Location['pathname']): { payload: [Location['pathname']] } => ({
        payload: [pathname],
      }),
    },
  },
  selectors: {
    historyLastItemPathname: state => state.historyLastItemPathname,
  },
})

export type historyActionTypes = SliceActions<typeof historySlice.actions>

export const selectors = historySlice.selectors
export default historySlice.reducer

export const { historySaveItemPathname } = historySlice.actions
