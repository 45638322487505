import { Button, Grid, Header, Icon, Input, List, Table } from 'semantic-ui-react'
import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import { refreshEndpointType } from 'AppSrc/refresh/types'
import {
  selectors as searchSelectors,
  searchUpdateSearchOrderRefreshIndex,
  searchUpdateSearchOrderTerm,
} from 'AppSrc/search/reducer'
import { useAppDispatch, useAppSelector } from 'AppSrc/store'
import parse from 'html-react-parser'
import { Link } from 'react-router-dom'
import RegistrantCard from 'AppSrc/components/registrants/RegistrantCard'
import { RestoreScrollPosition } from 'AppSrc/ui'
import debug from 'debug'
import helpers from 'ServerSrc/helpers/helpers'
import {
  refreshCancel,
  refreshRequest,
  selectors as refreshSelectors,
} from 'AppSrc/refresh/reducer'
import { hasOrderWithMatchingDate } from 'ServerSrc/helpers/itemsHelpers'
import config from 'Config/config'
import { getEventMetaDataCheckin, getEventMetaDataDate } from './helpers'
import dayjs from 'dayjs'
import { updateTopMenuContent } from 'AppSrc/components/topmenu/reducer'

// import './style.css'

debug.enable('lookup/index:*')
// const log = debug('lookup/index:log')
// const info = debug('lookup/index:info')
// const error = debug('lookup/index:error')

const Lookup = () => {
  const dispatch = useAppDispatch()
  const searchUpdateOrder = function (
    this: any,
    ...args: [_ev: ChangeEvent<HTMLInputElement> | KeyboardEvent | null, { value: string }]
  ) {
    return dispatch(searchUpdateSearchOrderTerm.apply(this, args))
  }

  const registrants = useAppSelector(state => refreshSelectors.registrants(state))
  const items = useAppSelector(state => refreshSelectors.items(state))
  const settings = useAppSelector(state => refreshSelectors.settings(state))

  const refreshStatus = useAppSelector(state => refreshSelectors.refreshStatus(state))

  const searchOrderResults = useAppSelector(state => refreshSelectors.searchOrderResults(state))
  const searchOrderTerm = useAppSelector(state => searchSelectors.searchOrderTerm(state))
  const searchOrderRefreshIndex = useAppSelector(state =>
    searchSelectors.searchOrderRefreshIndex(state)
  )

  const [isLoading, setIsLoading] = useState(false)
  const [addOrderIdx, setAddOrderIdx] = useState<`#${string}` | undefined>(undefined)

  const itemTopMenuName = 'Look up'

  const registrantsIdByOrderId = Object.entries(registrants).reduce((acc, [regId, regInfo]) => {
    Object.keys(regInfo['order_status']).forEach(id => {
      acc[id] = regId
    })
    return acc
  }, {} as { [id: string]: RegistrantIdType })

  useEffect(() => {
    dispatch(updateTopMenuContent(<div className="top-menu-title">{itemTopMenuName}</div>))
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (searchOrderRefreshIndex && refreshStatus[searchOrderRefreshIndex]?.match(/ing/)) {
      setIsLoading(true)
    } else {
      setIsLoading(false)
    }
  }, [refreshStatus])

  const inputRef = useRef<Input>(null)

  useEffect(() => {
    inputRef.current?.focus()
  }, [])

  const onCancel = () => {
    // cancel any previous request
    if (
      searchOrderRefreshIndex !== undefined &&
      refreshStatus[searchOrderRefreshIndex]?.match(/ing/)
    ) {
      dispatch(refreshCancel(searchOrderRefreshIndex))
    }
  }

  const onClick = () => {
    onCancel()

    if (
      !searchOrderTerm ||
      Number.isNaN(searchOrderTerm) ||
      registrantsIdByOrderId[searchOrderTerm]
    ) {
      dispatch(searchUpdateSearchOrderRefreshIndex(undefined))
      return
    }

    // send order search request
    const idx = `#${searchOrderTerm}` as `#${string}`
    const statuses = config.orders.lookupStatuses
    const refreshEndpoints = [
      {
        endpoint: `refresh/orders`,
        query: `search=${encodeURIComponent(
          searchOrderTerm
        )}&page=1&per_page=20&status=${statuses.join(',')}`,
      },
    ] as refreshEndpointType[]
    dispatch(refreshRequest(idx, 'skipRefresh', refreshEndpoints))
    dispatch(searchUpdateSearchOrderRefreshIndex(idx))
  }

  const isAddLoading = addOrderIdx && !!refreshStatus[addOrderIdx]?.match(/ing/)
  const onAdd = (orderId: number) => {
    const idx = `#${orderId}` as `#${string}`
    const refreshEndpoints = [
      {
        endpoint: `refresh/orders/${orderId}`,
        query: `filterByItems=true`,
      },
    ] as refreshEndpointType[]
    dispatch(refreshRequest(idx, 'skipRefresh', refreshEndpoints))
    setAddOrderIdx(idx)
  }

  // get all event IDs
  const eventIds = {} as { [id: string]: boolean }
  Object.values(items).forEach(item => {
    if (item.event) {
      eventIds[item.event.id] = true
      if (item.event.event_prov_id) eventIds[item.event.event_prov_id] = true
      if (item.event.event_post_id) eventIds[item.event.event_post_id] = true
      ;(item.event.event_products_info || []).forEach(info => {
        info.product_event_ids.forEach(id => {
          eventIds[id] = true
        })
      })
    }
  })

  // console.log({ eventIds })
  // console.log({ searchOrderResults })

  const validOrders = searchOrderResults.reduce((acc, order) => {
    acc[order.id] = hasOrderWithMatchingDate(order, eventIds, settings)
    return acc
  }, {} as { [id: string]: boolean })

  const isRefreshed =
    searchOrderRefreshIndex && refreshStatus[searchOrderRefreshIndex]?.match(/refreshed/)
  const isCanceled =
    searchOrderRefreshIndex && refreshStatus[searchOrderRefreshIndex]?.match(/cancel/)
  const isRejected =
    searchOrderRefreshIndex && refreshStatus[searchOrderRefreshIndex]?.match(/rejected/)

  let status = ''
  if (searchOrderRefreshIndex) {
    if (refreshStatus[searchOrderRefreshIndex]?.match(/ing/)) status = 'Loading...'
    else if (isRefreshed) status = 'No matches found in the past few months'
    else if (isCanceled) status = 'Search canceled'
    else if (isRejected) status = 'An error occurred'
  }

  const hideEmail = settings.uiRegistrants.hideEmailAddress

  return (
    <div>
      <RestoreScrollPosition />
      <Grid container style={{ marginTop: '5rem', marginBottom: '2rem' }}>
        <Grid.Row style={{ paddingTop: '3rem' }}>
          <Grid.Column computer={2} tablet={2} only="computer tablet" />
          <Grid.Column
            computer={12}
            tablet={12}
            mobile={16}
            textAlign="center"
            verticalAlign="middle"
          >
            <Header size="medium" style={{ fontWeight: 'normal' }}>
              Enter a search string (order number, name...)
            </Header>
            <Input
              ref={inputRef}
              focus
              icon={<Icon name="search" color="teal" inverted circular link onClick={onClick} />}
              loading={isLoading}
              placeholder="Search..."
              onChange={searchUpdateOrder}
              onKeyDown={(ev: KeyboardEvent) => {
                if (ev.key === 'Enter') {
                  onCancel()
                  onClick()
                } else if (ev.key === 'Escape') {
                  searchUpdateOrder(ev, { value: '' })
                  onCancel()
                }
              }}
              size="large"
              value={searchOrderTerm}
            />
          </Grid.Column>
          <Grid.Column computer={2} tablet={2} only="computer tablet" />
        </Grid.Row>
        <Grid.Row style={{ paddingTop: '2rem' }}>
          <Grid.Column computer={1} tablet={1} only="computer tablet" />
          <Grid.Column computer={14} tablet={14} mobile={16}>
            {searchOrderTerm && registrantsIdByOrderId[searchOrderTerm] ? (
              <RegistrantCard
                key={searchOrderTerm}
                reg={registrants[registrantsIdByOrderId[searchOrderTerm]]}
              />
            ) : isRefreshed && searchOrderResults.length > 0 ? (
              <Table celled compact striped size="small">
                <Table.Header style={{ fontSize: '90%' }}>
                  <Table.Row>
                    <Table.HeaderCell>Order&nbsp;ID</Table.HeaderCell>
                    <Table.HeaderCell>Name</Table.HeaderCell>
                    {hideEmail ? null : <Table.HeaderCell>Email</Table.HeaderCell>}
                    <Table.HeaderCell>Order Date</Table.HeaderCell>
                    <Table.HeaderCell>Status</Table.HeaderCell>
                    <Table.HeaderCell>Items</Table.HeaderCell>
                    <Table.HeaderCell>Checkins</Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">Action</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {searchOrderResults.map(order => (
                    <Table.Row key={order.id}>
                      <Table.Cell singleLine># {order.id}</Table.Cell>
                      <Table.Cell>
                        {order.billing.first_name} {order.billing.last_name}
                      </Table.Cell>
                      {hideEmail ? null : (
                        <Table.Cell>{helpers.obscureEmail(order.billing.email)}</Table.Cell>
                      )}
                      <Table.Cell singleLine>
                        {dayjs(order.date_created).format('M/D/YYYY h:mma')}
                      </Table.Cell>
                      <Table.Cell warning={!config.orders.statuses.includes(order.status)}>
                        {order.status}
                      </Table.Cell>
                      <Table.Cell>
                        <List
                          size="tiny"
                          style={{ display: 'flex', flexDirection: 'column', gap: '0.3em' }}
                        >
                          {order.line_items.map(item => (
                            <List.Item key={item.id}>
                              <List.Content
                                style={{ display: 'flex', flexDirection: 'column', gap: '0.3em' }}
                              >
                                <div>
                                  {parse(item.name)}
                                  {item.quantity !== 1 && <span> x {item.quantity} </span>}
                                </div>
                                {[getEventMetaDataDate(item.meta_data)]
                                  .filter(Boolean)
                                  .map(dates => (
                                    <div
                                      key="dates"
                                      style={{
                                        color: !validOrders[order.id] ? 'darkgray' : 'dimgray',
                                      }}
                                    >
                                      {dates}
                                    </div>
                                  ))}
                              </List.Content>
                            </List.Item>
                          ))}
                        </List>
                      </Table.Cell>
                      <Table.Cell>
                        <List
                          size="tiny"
                          style={{ display: 'flex', flexDirection: 'column', gap: '0.3em' }}
                        >
                          {order.meta_data
                            ?.filter(({ key }) => key === 'checkins')
                            .map(({ key, value }) => (
                              <List.Item key={key}>
                                <List.Content>
                                  <List>
                                    {getEventMetaDataCheckin(value).map(
                                      ([checkinKey, checkinValue]) => (
                                        <List.Item
                                          key={checkinKey}
                                          style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: '0.3em',
                                          }}
                                        >
                                          <List.Content style={{ whiteSpace: 'nowrap' }}>
                                            {dayjs(checkinValue).format('MMM D @ h:mma')}
                                          </List.Content>
                                        </List.Item>
                                      )
                                    )}
                                  </List>
                                </List.Content>
                              </List.Item>
                            ))}
                        </List>
                      </Table.Cell>
                      <Table.Cell
                        disabled={!registrantsIdByOrderId[order.id] && !validOrders[order.id]}
                        textAlign="center"
                      >
                        {registrantsIdByOrderId[order.id] ? (
                          <Button
                            as={Link}
                            positive
                            to={`/registrants/${registrantsIdByOrderId[order.id]}`}
                          >
                            Go
                          </Button>
                        ) : validOrders[order.id] ? (
                          <Button
                            color="blue"
                            loading={isAddLoading && addOrderIdx === `#${order.id}`}
                            size="medium"
                            onClick={() => onAdd(order.id)}
                          >
                            Add
                          </Button>
                        ) : (
                          <div>N/A</div>
                        )}
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            ) : (
              <Header textAlign="center" style={{ fontWeight: 100 }}>
                {status}
              </Header>
            )}
          </Grid.Column>
          <Grid.Column computer={1} tablet={1} only="computer tablet" />
        </Grid.Row>
      </Grid>
    </div>
  )
}

export default Lookup
