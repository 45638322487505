import debug from 'debug'
import { anyToString } from 'AppSrc/store/helpers'
import { store } from 'AppSrc/store'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { stateIndexes } from 'AppSrc/refresh/types'
import { stateIndexType } from 'AppSrc/refresh/types'
import { refreshCancel } from 'AppSrc/refresh/reducer'
import { selectors as refreshSelectors } from 'AppSrc/refresh/reducer'
import { msgSend } from 'AppSrc/websocket/reducer'

debug.enable('auth/reducer:*')
const log = debug('auth/reducer:log')
// const info = debug('auth/reducer:info')
const error = debug('auth/reducer:error')

export const key = 'auth'

const initialState = {
  authenticated: false,
  signInOngoing: false,
  signOutOngoing: false,
  signOutDone: false,
  authError: '',
}

const authSlice = createSlice({
  name: key,
  initialState,
  reducers: {
    authSignInRequest: (state, _action: PayloadAction<void>) => ({
      ...state,
      signInOngoing: true,
      signOutDone: false,
    }),
    authSignedIn: {
      reducer: (state, _action: PayloadAction<void>) => ({
        ...state,
        authenticated: true,
        signInOngoing: false,
      }),
      prepare: (userInfo: UserInfoType) => {
        log('AUTH_SIGNED_IN')
        localStorage.setItem('userInfo', JSON.stringify(userInfo))
        return { payload: undefined }
      },
    },
    authSignInRejected: {
      reducer: (state, action: { payload: Error }) => ({
        ...state,
        signInOngoing: false,
        authError: anyToString(action.payload),
      }),
      prepare: (err: Error): { payload: Error } => ({ payload: err }),
    },
    authSignOutRequest: (state, _action: PayloadAction<void>) => ({
      ...state,
      signOutOngoing: true,
      signOutDone: false,
    }),
    authSignedOut: {
      reducer: (state, _action: PayloadAction<void>) => ({
        ...state,
        authenticated: false,
        signOutOngoing: false,
        signOutDone: true,
      }),
      prepare: () => {
        Object.values(stateIndexes).forEach((idx: stateIndexType) => {
          // cancel all potential ongoing refresh actions
          const refreshStatus = refreshSelectors.refreshStatus(store.getState())
          if (((refreshStatus[idx] || '').match(/refreshing/) || []).length) {
            log(refreshStatus[idx], 'is ongoing?')
            store.dispatch(refreshCancel(idx))
          }
        })
        localStorage.removeItem('userInfo')
        return { payload: undefined }
      },
    },
    authSignOutRejected: {
      reducer: (state, action: { payload: Error }) => ({
        ...state,
        signOutOngoing: false,
        signOutDone: false,
        authError: anyToString(action.payload),
      }),
      prepare: (err: Error): { payload: Error } => ({ payload: err }),
    },
    authCheckSessionFailed: state => state,
  },
  selectors: {
    authenticated: state => state.authenticated,
    signInOngoing: state => state.signInOngoing,
    signOutOngoing: state => state.signOutOngoing,
    signOutDone: state => state.signOutDone,
    authError: state => state.authError,
  },
})

export const checkSession = (authenticated: boolean) => {
  try {
    // session cookie will be sent with message if it exists
    const userInfo = JSON.parse(localStorage.getItem('userInfo') || 'null')
    const msg = { cmd: 'session check', data: { userInfo, authenticated } }

    log('checkSession', msg)
    return msgSend(msg)
  } catch (err) {
    error(err)
    return authCheckSessionFailed()
  }
}

export const signedInMsg = () => {
  try {
    const userInfo = JSON.parse(localStorage.getItem('userInfo') || 'null')
    const msg = { cmd: 'signed in', data: { userInfo } }
    return msgSend(msg)
  } catch (err) {
    error(err)
    return authSignInRejected(err as Error)
  }
}

export type AuthActionTypes = SliceActions<typeof authSlice.actions>

export const selectors = authSlice.selectors
export default authSlice.reducer

export const {
  authSignInRequest,
  authSignedIn,
  authSignInRejected,
  authSignOutRequest,
  authSignedOut,
  authSignOutRejected,
  authCheckSessionFailed,
} = authSlice.actions
