import { TypedUseSelectorHook, useSelector, useDispatch } from 'react-redux'
import { configureStore } from '@reduxjs/toolkit'
import { createLogicMiddleware, LogicMiddleware } from 'redux-logic'
import { createReduxHistoryContext } from 'redux-first-history'
import { createBrowserHistory, createMemoryHistory } from 'history'
import axios, { Axios } from 'axios'
import debug from 'debug'
import rootReducer from './rootReducer'
import logic from './rootLogic'
import { msgListen } from 'AppSrc/websocket/reducer'
import { Store } from 'redux'

debug.enable('store/index:*')
// const log = debug('store/index:log')
// const info = debug('store/index:info')
// const error = debug('store/index:error')

declare global {
  interface Window {
    __PRELOADED_STATE__?: object
  }
}

// declare module '@reduxjs/toolkit' {
//   interface Store {
//     logicMiddleware: LogicMiddleware
//   }
// }

export interface Dependency {
  httpClient: Axios
}

export const getStore = ({
  isServer = false,
  serverUrl = '/',
}: { isServer?: boolean; serverUrl?: string } = {}) => {
  let preloadedState

  const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
    history: isServer
      ? createMemoryHistory({ initialEntries: [serverUrl] })
      : createBrowserHistory(),
  })

  // injected dependencies for logic
  const deps = { httpClient: axios } satisfies Dependency

  // add all redux-logic middlewares and deps
  const logicMiddleware = createLogicMiddleware(logic, deps)
  // logicMiddleware.monitor$.subscribe(x => log(x))

  if (!isServer) {
    preloadedState = window.__PRELOADED_STATE__
    delete window.__PRELOADED_STATE__
  }

  const store = configureStore({
    reducer: rootReducer(routerReducer),
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [
            'TOPMENU_UPDATE_CONTENT',
            'topmenu/updateTopMenuContent',
            'websocket/wsMsgError',
          ],
          ignoredPaths: ['topmenu.content.$$typeof', 'topmenu.content.type'],
        },
      }).concat(logicMiddleware, routerMiddleware),
    preloadedState,
    // devTools: process.env.NODE_ENV !== 'production' // disable devTools on production
  })
  const history = createReduxHistory(store)

  // used in serverRender
  store.logicMiddleware = logicMiddleware

  if (process.env.NODE_ENV !== 'production') {
    if (module.hot) {
      module.hot.accept('./', () => {
        const replacementReducer = rootReducer(routerReducer)
        store.replaceReducer(replacementReducer)
        store.dispatch(msgListen()) // restart listening to websocket
      })
    }
  }

  !isServer && store.dispatch(msgListen()) // start listening to websocket on app load

  return { store, history }
}

const isServer = typeof window === 'undefined'

export const { store, history } = getStore({ isServer })

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
