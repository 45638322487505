import React from 'react'
import { Link } from 'react-router-dom'
import { Grid, Card, Label, Item, Button, Accordion } from 'semantic-ui-react'
import dayjs from 'dayjs'
import debug from 'debug'
// eslint-disable-next-line import/extensions,import/no-unresolved
import { useAppSelector } from 'AppSrc/store'
import CountdownTimer from 'AppSrc/components/countdown'
import {
  getTimeRange,
  resizeImage,
  getImageSrc,
  formatDuration,
  getRegistrantsCountsForItem,
  truncateString,
} from './helpers'
import { selectors as refreshSelectors } from 'AppSrc/refresh/reducer'

debug.enable('itemCard/index:*')
// const log = debug('itemCard/index:log')
// const info = debug('itemCard/index:info')
// const error = debug('itemCard/index:error')

type Props = {
  disableLinks?: boolean
  gridCelled?: boolean | 'internally'
  item?: DbItemType
  shouldShowAttendance?: boolean
  shouldShowDescription?: boolean
  shouldShowItemButtons?: boolean
  truncateItemTitle?: number
}

const ItemCard = ({
  disableLinks = false,
  gridCelled = false,
  item = { slug: '' },
  shouldShowAttendance,
  shouldShowDescription,
  shouldShowItemButtons,
  truncateItemTitle = 60,
}: Props) => {
  const users = useAppSelector(state => refreshSelectors.users(state))
  const items = useAppSelector(state => refreshSelectors.items(state))
  const allItems = useAppSelector(state => refreshSelectors.allItems(state))
  const settings = useAppSelector(state => refreshSelectors.settings(state))
  const registrants = useAppSelector(state => refreshSelectors.registrants(state))

  const evt = item.event || ({} as DbItemEventType)
  const product = item.product || ({} as DbItemProductType)
  const itemUrlPath = `/items/${item.slug}`

  const titleRemoveRegex = /^\w+\s+\d+\s+(?:-|–|—)\s+(.*)(?:\s+w\/.*)?$/

  const columnWidth = shouldShowItemButtons ? 12 : 16
  const showDescription = shouldShowDescription && settings.uiItems.itemsShowDescription

  // series-number-of-weeks: 3-week-series | 4-week-series | 5-week-series | not-a-series
  // drop-in-dance-party-type: drop-in-dj-dance-party | drop-in-live-music-dance-party | not-a-drop-in
  const filterAttrs = evt.custom_fields['Class type']?.match(/series/i)
    ? ['series-number-of-weeks']
    : undefined

  return (
    <Card fluid>
      <Card.Content>
        <Grid
          celled={gridCelled}
          className="item-card-grid"
          container
          stretched
          verticalAlign="middle"
        >
          <Grid.Row className="item-card-grid-first-row">
            <Grid.Column computer={columnWidth} tablet={columnWidth} mobile={columnWidth}>
              <Item.Group>
                <Item>
                  <div
                    className="image"
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: 'auto',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {!product.image ? (
                      ''
                    ) : (
                      <div
                        className="image"
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          width: 'auto',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <Item.Image
                          key={product.image}
                          size="small"
                          rounded
                          src={resizeImage(product.image)}
                          as={disableLinks ? null : Link}
                          to={itemUrlPath}
                        />
                      </div>
                    )}
                    {product.image ||
                    !evt.custom_fields ||
                    (!evt.custom_fields['Instructor 1 Photo'] &&
                      !evt.custom_fields['Instructor 2 Photo']) ? (
                      ''
                    ) : (
                      <div
                        className="image"
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          width: 'auto',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        {[1, 2].map(
                          idx =>
                            evt.custom_fields?.[`Instructor ${idx} Photo`] && (
                              <Item.Image
                                key={idx}
                                size="mini"
                                circular
                                src={getImageSrc(evt.custom_fields[`Instructor ${idx} Photo`])}
                                as={disableLinks ? null : Link}
                                to={itemUrlPath}
                              />
                            )
                        )}
                      </div>
                    )}
                  </div>
                  <Item.Content verticalAlign="middle">
                    <Item.Header>
                      {truncateString(
                        (evt.title || product.name).replace(titleRemoveRegex, '$1'),
                        truncateItemTitle
                      )}
                    </Item.Header>
                    {!evt.custom_fields ? null : (
                      <Item.Meta>
                        <div>
                          {[1, 2].map(
                            idx =>
                              evt.custom_fields?.[`Instructor ${idx}`] && (
                                <div style={{ display: 'inline' }} key={idx}>
                                  {idx === 1 && <span style={{ color: '#aaa' }}>with</span>}
                                  {idx === 2 && <span style={{ fontWeight: 700 }}>&</span>}
                                  <span style={{ fontWeight: 700 }}>
                                    {evt.custom_fields[`Instructor ${idx}`].replace('&amp;', '&')}
                                  </span>
                                </div>
                              )
                          )}
                        </div>
                      </Item.Meta>
                    )}
                    {evt.start_date || !product.price ? null : (
                      <Item.Meta>
                        <div>
                          <div style={{ display: 'inline' }}>
                            <span style={{ fontWeight: 700 }}>
                              {`Price: ${product.price === '0' ? 'FREE' : `$${product.price}`}`}
                            </span>
                          </div>
                        </div>
                      </Item.Meta>
                    )}
                    {!evt.start_date || !evt.week_number ? null : (
                      <Item.Extra className="item-extra">
                        <Label color="teal" size="medium">
                          {getTimeRange(evt.start_date, evt.end_date)}
                        </Label>
                        <Label size="medium">
                          Week &nbsp;
                          {evt.week_number}
                        </Label>
                        {!evt.custom_fields?.['Role type'] ? (
                          ''
                        ) : (
                          <Label basic size="medium">
                            {evt.custom_fields['Role type']}
                          </Label>
                        )}
                      </Item.Extra>
                    )}
                  </Item.Content>
                </Item>
              </Item.Group>
            </Grid.Column>
            {!shouldShowItemButtons ? null : (
              <Grid.Column
                computer={4}
                tablet={4}
                mobile={16}
                textAlign="center"
                verticalAlign={showDescription ? 'bottom' : 'middle'}
                stretched
              >
                {!evt.start_date ? null : (
                  <Button
                    size="large"
                    as={disableLinks ? null : Link}
                    color="blue"
                    to={itemUrlPath}
                    className="class-started-button"
                  >
                    <CountdownTimer
                      startsIn="Starts in"
                      started="Started"
                      startedSuffix="ago"
                      updateFunc={() => formatDuration(dayjs(), dayjs(evt.start_date))}
                    />
                  </Button>
                )}
                {evt.start_date ? null : (
                  <Button
                    size="large"
                    as={disableLinks ? null : Link}
                    color="blue"
                    to={itemUrlPath}
                    className="item-button"
                  >
                    See Registrants
                  </Button>
                )}
              </Grid.Column>
            )}
          </Grid.Row>
          {!shouldShowAttendance ? null : (
            <Grid.Row className="item-statistics">
              <Grid.Column computer={8} tablet={8} mobile={8}>
                {getRegistrantsCountsForItem({
                  checkedInStatus: 'any',
                  selectedItem: item.slug,
                  allItems,
                  items,
                  registrants,
                  settings,
                  users,
                  filterAttrs,
                })}
              </Grid.Column>
              <Grid.Column textAlign="right" computer={8} tablet={8} mobile={8}>
                Checked-in:{' '}
                {getRegistrantsCountsForItem({
                  checkedInStatus: true,
                  selectedItem: item.slug,
                  allItems,
                  items,
                  registrants,
                  settings,
                  users,
                })}
              </Grid.Column>
            </Grid.Row>
          )}
          {!showDescription || !evt.description ? null : (
            <Grid.Row className="item-description">
              <Accordion
                exclusive={false}
                panels={[
                  {
                    key: `accordion-description-${evt.slug}`,
                    title: 'DESCRIPTION',
                    content: {
                      content: <div dangerouslySetInnerHTML={{ __html: evt.description }} />,
                    },
                  },
                ]}
              />
            </Grid.Row>
          )}
        </Grid>
      </Card.Content>
    </Card>
  )
}

export default ItemCard
