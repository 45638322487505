import { Input } from 'semantic-ui-react'
import { RefObject } from 'react'
import debug from 'debug'

debug.enable('search/helpers:*')
// const log = debug('search/helpers:log')
// const info = debug('search/helpers:info')
// const error = debug('search/helpers:error')

let searchInputFieldRef: RefObject<Input>['current']

type FnType = (_null: null, { value }: { value: string }) => void

export const onClick = (fn: FnType) => () => fn(null, { value: '' })

export const onKeyDown = (fn: FnType) => (ev: KeyboardEvent) => {
  if (ev.key === 'Escape' || ev.keyCode === 27) {
    fn(null, { value: '' })
    return
  }
  focusSearchInputField()
}

let searchInputFieldRefTimeout: NodeJS.Timeout
export const focusSearchInputField = () => {
  searchInputFieldRef &&
    (searchInputFieldRefTimeout = setTimeout(() => searchInputFieldRef?.focus(), 100))
}

let focusSearchInputFieldOnStartTimeout: NodeJS.Timeout
export const focusSearchInputFieldOnStart = (input: RefObject<Input>['current']) => {
  searchInputFieldRef = input
  input && (focusSearchInputFieldOnStartTimeout = setTimeout(() => input?.focus(), 100))
}

export const clearSearchInputFieldTimeouts = () => {
  clearTimeout(searchInputFieldRefTimeout)
  clearTimeout(focusSearchInputFieldOnStartTimeout)
}
