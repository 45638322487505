import dayjs from 'dayjs'
import {
  getItemInfo,
  getRegistrantCountsGroups,
  getRegistrantsSubheader,
} from 'AppSrc/components/registrants/helpers'

export const getTimeRange = (startDate: string, endDate: string) =>
  `${dayjs(startDate).format('h:mm')} - ${dayjs(endDate).format('h:mm A')}`

export const resizeImage = (html: string) => `${html}`.replace(/1\d\dx1\d\d/, '150x150')

export const getImageSrc = (html: string) => resizeImage(html).match(/^.* src="([^"]+)" .*$/)?.[1]

export const formatDuration = (from: dayjs.Dayjs, to: dayjs.Dayjs) => {
  const diffMin = to.diff(from, 'minute')
  const hours = diffMin / 60
  const mins = Math.abs(diffMin % 60)
  const value = `${Math.trunc(hours)} hr${hours > 1 ? 's' : ''} ${mins} min${mins > 1 ? 's' : ''}`
  return { value, neg: hours < 0 }
}

export const getRegistrantsCountsForItem = ({
  selectedItem,
  checkedInStatus,
  items,
  allItems,
  registrants,
  settings,
  users,
  filterAttrs,
}: {
  selectedItem: string
  checkedInStatus: boolean | 'any'
  items: DbItemsType
  allItems: DbItemsType
  registrants: RegistrantsType
  settings: settingsType
  users: DbUsersType
  filterAttrs?: string[]
}) => {
  const match = { params: { itemId: selectedItem } }
  const activeSearchString = ''

  const { itemId, itemIdArray } = getItemInfo({
    items,
    allItems,
    settings,
    match,
  })

  const { registrantCounts, registrantGroups } = getRegistrantCountsGroups({
    registrants,
    itemIdArray,
    activeSearchString,
    users,
    settings,
    filterAttrs,
  })

  if (registrantGroups.length === 0) {
    return null
  }

  return getRegistrantsSubheader(checkedInStatus, registrantCounts, activeSearchString, itemId)
}

export const truncateString = (string = '', maxLength = 50) =>
  string.length > maxLength ? `${string.substring(0, maxLength)}…` : string

// const padNumber = (n: number, size: number) => ('0'.repeat(size - 1) + n).slice(-size)
