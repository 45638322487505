import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { decode as heDecode } from 'html-entities'
import debug from 'debug'
import { Grid, Card, Label, Item, Icon, Loader, Button } from 'semantic-ui-react'
import { useAppDispatch, useAppSelector } from 'AppSrc/store'
import MetaDataVaccination from 'AppSrc/metadata/MetaDataVaccination'
import Checkin from 'AppSrc/checkin/component'
import MetaDataBirthday from 'AppSrc/metadata/MetaDataBirthday'
import { selectors as refreshSelectors } from 'AppSrc/refresh/reducer'
import OrderStatus from 'AppSrc/order/component'
import OrderNotes from 'AppSrc/components/ordernotes'
import RegistrantCardItemExtra from './RegistrantCardItemExtra'
import Attendance from 'AppSrc/components/attendance'
import { processCheckinRequest } from 'AppSrc/checkin/helpers'
import {
  getRegistrantNames,
  getRegistrantItemAttrs,
  stripItemName,
  getRegistrantItemKeysByName,
  getItemAttrsClassName,
  getRegistrantCheckinsByName,
  getRegistrantRegItemsByName,
  getRegistrantOrderIdsByName,
  getCheckinWeek,
  itemsKeysSort,
} from './helpers'
import Visibility from 'AppSrc/components/visibility/Visibility'

debug.enable('registrants/RegistrantCard:*')
// const log = debug('registrants/RegistrantCard:log')
// const info = debug('registrants/RegistrantCard:info')
// const error = debug('registrants/RegistrantCard:error')

type Props = {
  reg: RegistrantType
}

const RegistrantCard = ({ reg }: Props) => {
  const dispatch = useAppDispatch()
  const settings = useAppSelector(state => refreshSelectors.settings(state))
  const [loading, setLoading] = useState<boolean>(true)

  const onClickFunc = (
    name: string,
    regId: number | string,
    checkins: [CheckinsType],
    checkinItems: [RegistrantItemsType]
  ) =>
    processCheckinRequest({
      name,
      regId,
      checkins,
      checkinItems,
      dispatch,
      settings,
    })

  const getRegEditCardLink = (index: number, regId: number | string) =>
    `/registrants/${encodeURIComponent(regId)}${index ? `?index=${index}` : ''}`

  const hideEmail = settings.uiRegistrants && settings.uiRegistrants.hideEmailAddress
  const hideAttendance = settings.uiItems && settings.uiItems.itemsWithoutEvents

  if (loading) {
    return (
      <Card fluid style={{ backgroundColor: 'rgba(255, 255, 255, 0.75)' }}>
        <Card.Content className="registrant-card loading">
          <Visibility
            fallbackInView={true}
            triggerOnce={false}
            onChange={inView => {
              if (inView) setLoading(false)
            }}
            style={{ flexGrow: 1 }}
          >
            <Loader className="registrant-card-loader" active inline="centered" size="large" />
          </Visibility>
        </Card.Content>
      </Card>
    )
  }

  return (
    <Card fluid>
      <Card.Content className="registrant-card loaded">
        <Visibility
          fallbackInView={true}
          initialInView={true}
          triggerOnce={false}
          onChange={inView => {
            // FIXME: should we unload the content when out of view?
            // if (!inView) setLoading(true)
          }}
          style={{ flexGrow: 1 }}
        >
          <Grid container verticalAlign="middle" divided="vertically">
            {getRegistrantNames(reg).map((name, index) => (
              <Grid.Row key={name}>
                <Grid.Column computer={12} tablet={11} mobile={16}>
                  <Item.Group>
                    <Item>
                      <Item.Image
                        size="small"
                        circular
                        src={reg.avatar}
                        as={Link}
                        to={getRegEditCardLink(index, reg.id)}
                        className="avatar"
                      />
                      <Item.Content verticalAlign="middle" className="registrant-card-content">
                        <Item.Header>
                          <div className="registrant-name">{heDecode(name)}</div>
                        </Item.Header>
                        <Item.Meta>
                          {index !== 0 ? null : (
                            <div
                              style={{
                                display: hideEmail ? 'none' : 'inline-block',
                              }}
                              className="registrant-email-id"
                            >
                              {reg.email || reg.id}
                            </div>
                          )}
                          <MetaDataBirthday key={`${reg.id}-birthday`} regId={reg.id.toString()} />
                          {settings.uiRegistrants.hideVaccinationStatus ? null : (
                            <MetaDataVaccination
                              key={`${reg.id}-vaccination`}
                              orderIds={getRegistrantOrderIdsByName(reg.items, reg, name)}
                              regId={reg.id.toString()}
                              regOrderExtra={reg.order_extra}
                            />
                          )}
                          {Object.keys(reg.order_status || {})
                            .filter(
                              orderId =>
                                getRegistrantOrderIdsByName(reg.items, reg, name).indexOf(
                                  orderId
                                ) !== -1
                            )
                            .map(orderId => (
                              <OrderStatus
                                key={orderId}
                                reg={reg}
                                orderId={Number(orderId)}
                                hideWhenPaid={settings.uiRegistrants.hideOrderStatusWhenPaid}
                              />
                            ))}
                          <OrderNotes className="order-notes" reg={reg} name={name} />
                        </Item.Meta>
                        <Item.Description>
                          <Label.Group size="medium" className="item-label-group">
                            {/* FIXME: should we sort the items by event start date instead if it exists? */}
                            {getRegistrantItemKeysByName(reg.items, reg, name)
                              .sort(itemsKeysSort(reg.items))
                              .filter(key => reg.items[key].item_quantity !== 0)
                              .map(key => (
                                <div key={key} className="item-labels">
                                  <Label
                                    className="item-label item-label-button"
                                    as={Button}
                                    onClick={() =>
                                      onClickFunc(
                                        name,
                                        reg.id.toString(),
                                        [
                                          getRegistrantCheckinsByName(
                                            reg.checkins,
                                            { [key]: reg.items[key] },
                                            reg,
                                            name
                                          ),
                                        ],
                                        [
                                          getRegistrantRegItemsByName(
                                            { [key]: reg.items[key] },
                                            reg,
                                            name
                                          ),
                                        ]
                                      )
                                    }
                                  >
                                    {!reg.checkins[reg.items[key].checkin_key] ? (
                                      ''
                                    ) : (
                                      <Icon name="checkmark" />
                                    )}
                                    {reg.items[key].item_quantity === 1 ? (
                                      ''
                                    ) : (
                                      <div style={{ display: 'inline' }}>
                                        {`${reg.items[key].item_quantity} x `}
                                      </div>
                                    )}
                                    <div
                                      style={{ display: 'inline' }}
                                      className="mobile screen-hidden tablet screen-hidden"
                                    >
                                      {getCheckinWeek(reg.items[key].checkin_key) &&
                                        `${getCheckinWeek(reg.items[key].checkin_key)} – `}
                                    </div>
                                    <div
                                      style={{ display: 'inline' }}
                                      className="mobile screen-hidden tablet screen-hidden"
                                    >
                                      {stripItemName(reg.items[key].item_name, {
                                        removeContentInParenthesis: true,
                                      })}
                                    </div>
                                    <div
                                      style={{ display: 'inline' }}
                                      className="mobile screen-only tablet screen-only"
                                    >
                                      {stripItemName(reg.items[key].item_name, {
                                        removeContentInParenthesis: true,
                                      }).replace(/^.*20\d\d /, '')}
                                    </div>
                                  </Label>
                                  {!Object.values(getRegistrantItemAttrs(reg, key)).length ? (
                                    ''
                                  ) : (
                                    <div style={{ display: 'inline', whiteSpace: 'nowrap' }}>
                                      <Label
                                        basic
                                        className={`item-label tablet screen-hidden mobile screen-hidden ${getItemAttrsClassName(
                                          reg,
                                          key
                                        )}`}
                                      >
                                        <div>
                                          {Object.values(getRegistrantItemAttrs(reg, key))
                                            .map(attrVal => `${attrVal.join(', ')}`)
                                            .join(' – ')}
                                        </div>
                                      </Label>
                                      <Label
                                        basic
                                        className={`item-label mobile screen-only tablet screen-only ${getItemAttrsClassName(
                                          reg,
                                          key
                                        )}`}
                                      >
                                        <div>
                                          {Object.values(getRegistrantItemAttrs(reg, key))
                                            .map(
                                              attrVal =>
                                                `${attrVal
                                                  .map(s => {
                                                    if (s.match(/follower|leader|solo/i))
                                                      return s[0] // abbreviate role on small screens
                                                    return s
                                                  })
                                                  .join(', ')}`
                                            )
                                            .join(' – ')}
                                        </div>
                                      </Label>
                                    </div>
                                  )}
                                  {hideAttendance ? (
                                    ''
                                  ) : (
                                    <Label basic className="item-label item-label-attendance">
                                      <Attendance reg={reg} regItemKey={key} name={name} />
                                    </Label>
                                  )}
                                </div>
                              ))}
                          </Label.Group>
                        </Item.Description>
                        <Item.Extra>
                          <RegistrantCardItemExtra reg={reg} name={name} />
                        </Item.Extra>
                      </Item.Content>
                    </Item>
                  </Item.Group>
                </Grid.Column>
                <Grid.Column computer={4} tablet={5} mobile={16} textAlign="center">
                  <Checkin
                    name={name}
                    regId={reg.id.toString()}
                    checkins={[
                      getRegistrantCheckinsByName(reg.checkins || {}, reg.items || {}, reg, name),
                    ]}
                    checkinItems={[getRegistrantRegItemsByName(reg.items, reg, name)]}
                  />
                </Grid.Column>
              </Grid.Row>
            ))}
          </Grid>
        </Visibility>
      </Card.Content>
    </Card>
  )
}

// RegistrantCard.propTypes = {
//   reg: PropTypes.objectOf(() => true).isRequired,
// }

export default RegistrantCard
