import { createLogic } from 'redux-logic'
import debug from 'debug'
import { RootState, Dependency } from 'AppSrc/store'
import { CheckinMeta, CheckinPayload } from './types'
import {
  CheckinActionTypes,
  checkinCancel,
  checkinFulfilled,
  checkinRejected,
  checkinRequest,
} from './reducer'

debug.enable('checkin/logic:*')
const log = debug('checkin/logic:log')
// const info = debug('checkin/logic:info')
const error = debug('checkin/logic:error')

// const delay = 4 // 4s delay for interactive use of cancel/take latest

export const checkinLogic = createLogic<
  RootState, // State
  CheckinPayload, // Payload
  CheckinMeta, // Meta
  Dependency, // Dependency
  CheckinActionTypes
>({
  type: checkinRequest.type,
  cancelType: checkinCancel.type,
  // latest: true, // take latest only

  // use axios injected as httpClient from configureStore logic deps
  // we also have access to getState and action in the first argument
  // but they were not needed for this particular code
  async process({ httpClient, getState, action }, dispatch, done) {
    const idx = action.payload[0]
    const regId = action.payload[1] as string // registrant ID
    const checkinData = action.payload[2]
    const checkinUndo = action.payload[3] as boolean
    const clearSearchOnCheckIn = action.payload[4] as boolean

    // log('checkinLogic index', idx)
    // log('checkinLogic checkinData', checkinData)
    // log('checkinLogic checkinUndo', checkinUndo)
    log('checkinLogic', action.payload)

    try {
      await httpClient.put(
        `/api/registrants/${encodeURIComponent(btoa(regId))}?reqId=client&filterByItems=true`,
        checkinData
      )
      dispatch(checkinFulfilled(idx, checkinUndo, clearSearchOnCheckIn)) // FIXME? should we check resp?
    } catch (err) {
      error(err) // might be a render err
      dispatch(checkinRejected(idx, checkinUndo, err as Error))
    } finally {
      done()
    }
  },
})

export default checkinLogic
