import debug from 'debug'
import { getCheckinData, getDate } from './helpers'
import { createSlice } from '@reduxjs/toolkit'
import { selectors as searchSelectors } from 'AppSrc/search/reducer'
import { store } from 'AppSrc/store'
import { anyToString } from 'AppSrc/store/helpers'
import { searchUpdateSearchString } from 'AppSrc/search/reducer'

debug.enable('checkin/reducer:*')
// const log = debug('checkin/reducer:log')
// const info = debug('checkin/reducer:info')
// const error = debug('checkin/reducer:error')

export const key = 'checkin'

const initialState = {
  checkinStatus: {} as Record<string, string>,
  checkinDate: {} as Record<string, string>,
  errorData: {} as Record<string, string>,
}

const checkinSlice = createSlice({
  name: key,
  initialState,
  reducers: {
    checkinRequest: {
      reducer: (
        state,
        action: {
          payload: [
            idx: string,
            regId: number | string,
            checkinData: {
              orderId: string
              checkins: object
            }[],
            checkinUndo: CheckinUndoType,
            clearSearchOnCheckIn: boolean
          ]
        }
      ) => {
        const idx = action.payload[0]
        const checkinUndo = action.payload[3]
        return {
          ...state,
          checkinStatus: {
            ...state.checkinStatus,
            [idx]: checkinUndo ? 'undoing checkin' : 'checking in',
          },
          checkinDate: { ...state.checkinDate, [idx]: getDate() },
        }
      },
      prepare: (
        idx,
        regId,
        { checkinUndo, settings, clearSearchOnCheckIn, ...checkinData }
      ): {
        payload: [
          idx: string,
          regId: number | string,
          checkinData: {
            orderId: string
            checkins: object
          }[],
          checkinUndo: CheckinUndoType,
          clearSearchOnCheckIn: boolean
        ]
      } => ({
        payload: [
          idx,
          regId,
          getCheckinData({ checkinUndo, settings, ...checkinData }),
          checkinUndo,
          clearSearchOnCheckIn,
        ],
      }),
    },
    checkinCancel: {
      reducer: (state, action: { payload: [string] }) => {
        const idx = action.payload[0]
        return {
          ...state,
          checkinStatus: { ...state.checkinStatus, [idx]: 'cancelled' },
          checkinDate: { ...state.checkinDate, [idx]: getDate() },
        }
      },
      prepare: (idx: string): { payload: [string] } => ({
        payload: [idx],
      }),
    },
    checkinFulfilled: {
      reducer: (state, action: { payload: [string, CheckinUndoType] }) => {
        const idx = action.payload[0]
        const checkinUndo = action.payload[1]
        return {
          ...state,
          checkinStatus: {
            ...state.checkinStatus,
            [idx]: checkinUndo ? 'not checked in' : 'checked in',
          },
          checkinDate: { ...state.checkinDate, [idx]: getDate() },
        }
      },
      prepare: (
        idx: string,
        checkinUndo: CheckinUndoType,
        clearSearchOnCheckIn: boolean
      ): { payload: [string, CheckinUndoType] } => {
        if (clearSearchOnCheckIn) {
          // clear search on successful checkin if search string is set
          if (searchSelectors.activeSearchString(store?.getState()) !== '') {
            store.dispatch(searchUpdateSearchString(null, { value: '' }))
          }
        }
        return { payload: [idx, checkinUndo] }
      },
    },
    checkinRejected: {
      reducer: (state, action: { payload: [string, CheckinUndoType, Error] }) => {
        const idx = action.payload[0]
        const checkinUndo = action.payload[1]
        const err = action.payload[2] as Error
        return {
          ...state,
          checkinStatus: {
            ...state.checkinStatus,
            [idx]: checkinUndo ? 'undo checkin rejected' : 'checkin rejected',
          },
          checkinDate: { ...state.checkinDate, [idx]: getDate() },
          errorData: { ...state.errorData, [idx]: anyToString(err) },
        }
      },
      prepare: (
        idx: string,
        checkinUndo: CheckinUndoType,
        err: Error
      ): { payload: [string, CheckinUndoType, Error] } => ({ payload: [idx, checkinUndo, err] }),
    },
  },
  selectors: {
    checkinStatus: state => state.checkinStatus,
    checkinDate: state => state.checkinDate,
    errorData: state => state.errorData,
  },
})

export type CheckinActionTypes = SliceActions<typeof checkinSlice.actions>

export const selectors = checkinSlice.selectors
export default checkinSlice.reducer

export const { checkinRequest, checkinCancel, checkinFulfilled, checkinRejected } =
  checkinSlice.actions
