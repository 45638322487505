import { WsMsgRcvType, WsMsgRcvUnkownType } from 'AppSrc/websocket/types'
import {
  RefreshData,
  refreshEndpointFieldEndpointType,
  refreshEndpointType,
  stateIndexType,
  stateIndexes,
} from './types'
import { getDate, itemsSelectedIfEvent } from './helpers'
import { anyToString } from 'AppSrc/store/helpers'
import debug from 'debug'
import { diff } from 'deep-diff'
import { objHasKey } from 'AppSrc/components/registrants/helpers'
import { createSlice } from '@reduxjs/toolkit'
import { refreshEndpoints, RefreshRequestPayload } from './types'
import { AxiosError } from 'axios'
import { wsMsgReceived } from 'AppSrc/websocket/reducer'

debug.enable('refresh/reducer:*')
const log = debug('refresh/reducer:log')
// const info = debug('refresh/reducer:info')
// const error = debug('refresh/reducer:error')

export const key = 'refresh'

export const initialItem = {
  // Fake initial item
  1: {
    slug: 'item-slug',
    event: {
      id: '1',
      title: 'Items (classes/dance passes) need to be loaded...',
      slug: 'item-event-slug',
      description: 'Event description',
      start_date: '2024-01-04 21:30',
      start_dates: ['2024-01-04 21:30'],
      end_date: '2024-01-04 23:30',
      week_number: 1,
      week_numbers: [1],
      categories: [],
      custom_fields: {},
    },
  },
} satisfies DbItemsType

const initialState: {
  items: DbItemsType
  allItems: DbItemsType
  registrants: RegistrantsType
  users: DbUsersType
  settings: settingsType
  refreshStatus: { [idx in stateIndexType]?: string }
  refreshDate: { [idx in stateIndexType]?: string }
  errorData: { [idx in stateIndexType]?: string }
  searchOrderResults: wcOrderType[]
} = {
  items: initialItem,
  allItems: initialItem,
  // validProductIdsForCheckin: {},
  registrants: {} as RegistrantsType,
  users: {} as DbUsersType,
  settings: {
    // this must match what's on the server
    app: {
      version: undefined,
    },
    events: {
      start_date: null,
      end_date: null,
    },
    itemsDeselected: {},
    uiRegistrants: {
      separateCheckedIn: false,
      clearSearchOnCheckIn: true,
      hideEmailAddress: false,
      hideVaccinationStatus: true,
      hideOrderStatusWhenPaid: true,
      hideOrdersWhenCancelled: true,
      hideOrdersWhenRefunded: true,
    },
    uiItems: {
      itemsWithoutEvents: false,
      itemsShowDescription: true,
      itemsPartOfBundle: false,
      itemsShowDanceInStats: true,
    },
    uiUsers: {
      skipUnusedUsers: true,
      skipUnusedUsersOptions: {
        defaultValue: 1,
        options: [
          { key: 1, text: 'for 1 year', value: 1 },
          { key: 2, text: 'for 2 years', value: 2 },
          { key: 3, text: 'for 3 years', value: 3 },
          { key: 4, text: 'for 4 years', value: 4 },
          { key: 5, text: 'for 5 years', value: 5 },
          { key: 10, text: 'for 10 years', value: 10 },
        ],
      },
    },
  } as settingsType,
  refreshStatus: {} as { [idx in stateIndexType]?: string },
  refreshDate: {} as { [idx in stateIndexType]: string },
  errorData: {} as { [idx in stateIndexType]: string },
  searchOrderResults: [] as wcOrderType[],
}

const refreshSlice = createSlice({
  name: key,
  initialState,
  reducers: {
    refreshRequest: {
      reducer: (state, action: { payload: RefreshRequestPayload }) => {
        const idx = action.payload[0]
        const refreshEndpoints = action.payload[2]
        const newStatus = 'refreshing'
        const newDate = getDate()
        const refreshStatus = { ...state.refreshStatus, [idx]: newStatus }
        const refreshDate = { ...state.refreshDate, [idx]: newDate }

        if (idx === stateIndexes.REFRESH_IDX_BUTTON_REFRESH) {
          const autoIdx = stateIndexes.REFRESH_IDX_MSGREC_DISPATCH_REFRESH_REQUEST
          refreshStatus[autoIdx] = newStatus
          refreshDate[autoIdx] = newDate

          const trackIdx = stateIndexes.REFRESH_IDX_BUTTON_REFRESH_TRACK
          refreshStatus[trackIdx] = newStatus
          refreshDate[trackIdx] = newDate
        }

        refreshEndpoints
          .map((i: refreshEndpointType) => i.endpoint)
          .filter(group => group !== undefined)
          .forEach(group => {
            refreshStatus[group] = newStatus
            refreshDate[group] = newDate
          })

        return {
          ...state,
          refreshStatus,
          refreshDate,
        }
      },
      prepare: (
        idx = stateIndexes.REFRESH_IDX_STORE_INIT as stateIndexType,
        refreshType = 'skipRefresh',
        refreshSelectedEndpoints = refreshEndpoints
      ): { payload: RefreshRequestPayload } => ({
        payload: [idx, refreshType, refreshSelectedEndpoints],
      }),
    },

    refreshCancel: {
      reducer: (state, action: { payload: [stateIndexType] }) => {
        const idx = action.payload[0]
        const newStatus = 'cancelled'
        const newDate = getDate()
        const refreshStatus = { ...state.refreshStatus, [idx]: newStatus }
        const refreshDate = { ...state.refreshDate, [idx]: newDate }

        if (idx === stateIndexes.REFRESH_IDX_BUTTON_REFRESH) {
          const autoIdx = stateIndexes.REFRESH_IDX_MSGREC_DISPATCH_REFRESH_REQUEST
          refreshStatus[autoIdx] = newStatus
          refreshDate[autoIdx] = newDate

          const trackIdx = stateIndexes.REFRESH_IDX_BUTTON_REFRESH_TRACK
          refreshStatus[trackIdx] = newStatus
          refreshDate[trackIdx] = newDate
        }

        return {
          ...state,
          refreshStatus,
          refreshDate,
        }
      },
      prepare: (idx: stateIndexType): { payload: [stateIndexType] } => ({ payload: [idx] }),
    },

    refreshFulfilled: {
      reducer: (
        state,
        action: { payload: [stateIndexType, RefreshData, Array<refreshEndpointFieldEndpointType>] }
      ) => {
        const idx = action.payload[0]
        const refreshData = action.payload[1]
        const refreshGroups = action.payload[2]
        // log('REFRESH_FULFILLED', idx)

        const newStatus = 'refreshed'
        const newDate = getDate()
        const refreshStatus = { ...state.refreshStatus, [idx]: newStatus }
        const refreshDate = { ...state.refreshDate, [idx]: newDate }
        // if (idx === stateIndexes.REFRESH_IDX_BUTTON_REFRESH) {
        //   const autoIdx = stateIndexes.REFRESH_IDX_MSGREC_DISPATCH_REFRESH_REQUEST
        //   refreshStatus[autoIdx] = newStatus
        //   refreshDate[autoIdx] = newDate
        // }

        const itemsIndex = refreshGroups.indexOf(stateIndexes.REFRESH_IDX_ITEMS)
        const registrantsIndex = refreshGroups.indexOf(stateIndexes.REFRESH_IDX_REGISTRANTS)
        const usersIndex = refreshGroups.indexOf(stateIndexes.REFRESH_IDX_USERS)
        //
        const items = itemsIndex >= 0 ? {} : state.items
        const allItems = itemsIndex >= 0 ? (refreshData[itemsIndex] as DbItemsType) : state.allItems
        if (itemsIndex >= 0) {
          Object.values(allItems)
            .filter(itemsSelectedIfEvent(state.settings))
            .reduce((acc: typeof items, item: DbItemType) => {
              acc[item.slug] = item
              return acc
            }, items)
        }
        // const validProductIdsForCheckin = (itemsIndex >= 0) ? getValidProductIdsForCheckin(allItems, items) : state.validProductIdsForCheckin
        const registrants =
          registrantsIndex >= 0
            ? (refreshData[registrantsIndex] as RegistrantsType)
            : state.registrants
        const users = usersIndex >= 0 ? (refreshData[usersIndex] as DbUsersType) : state.users
        refreshGroups.forEach(group => {
          refreshStatus[group] = newStatus
          refreshDate[group] = newDate
        })
        if (refreshGroups) {
          refreshStatus[idx] += ` ${refreshGroups.join('/')}`
        }
        const searchOrderResultsIndex = refreshGroups.indexOf('refresh/orders')
        return {
          ...state,
          refreshStatus,
          refreshDate,
          items,
          allItems,
          // validProductIdsForCheckin,
          registrants,
          users,
          ...(searchOrderResultsIndex !== -1 && {
            searchOrderResults: refreshData[searchOrderResultsIndex] as wcOrderType[],
          }),
        }
      },
      prepare: (
        idx: stateIndexType,
        refreshData: RefreshData,
        refreshGroups: Array<refreshEndpointFieldEndpointType>
      ): { payload: [stateIndexType, RefreshData, Array<refreshEndpointFieldEndpointType>] } => ({
        payload: [idx, refreshData, refreshGroups],
      }),
    },

    refreshRejected: {
      reducer: (
        state,
        action: {
          payload: [stateIndexType, Array<refreshEndpointFieldEndpointType>, string | AxiosError]
        }
      ) => {
        const idx = action.payload[0]
        const refreshGroups = action.payload[1]
        const err = action.payload[2]
        log('REFRESH_REJECTED', idx, err)
        const newStatus = 'rejected'
        const newDate = getDate()
        const newError = anyToString(err)
        const refreshStatus = { ...state.refreshStatus, [idx]: newStatus }
        const refreshDate = { ...state.refreshDate, [idx]: newDate }
        const errorData = { ...state.errorData, [idx]: newError }

        refreshGroups.forEach(group => {
          refreshStatus[group] = newStatus
          refreshDate[group] = newDate
          errorData[group] = newError
        })

        if (idx === stateIndexes.REFRESH_IDX_BUTTON_REFRESH) {
          // const autoIdx = stateIndexes.REFRESH_IDX_MSGREC_DISPATCH_REFRESH_REQUEST
          // refreshStatus[autoIdx] = newStatus
          // refreshDate[autoIdx] = newDate
          // errorData[autoIdx] = newError

          const trackIdx = stateIndexes.REFRESH_IDX_BUTTON_REFRESH_TRACK
          refreshStatus[trackIdx] = newStatus
          refreshDate[trackIdx] = newDate
          errorData[trackIdx] = newError
        }

        return {
          ...state,
          refreshStatus,
          refreshDate,
          errorData,
        }
      },
      prepare: (
        idx: stateIndexType,
        refreshGroups: Array<refreshEndpointFieldEndpointType>,
        err: string | AxiosError
      ): {
        payload: [stateIndexType, Array<refreshEndpointFieldEndpointType>, string | AxiosError]
      } => ({
        payload: [idx, refreshGroups, err],
      }),
    },

    refreshUpdateInfo: {
      reducer: (
        state,
        action: {
          payload: [string, Array<refreshEndpointFieldEndpointType>]
        }
      ) => {
        const newStatus = action.payload[0]
        const newDate = getDate()
        const refreshGroups = action.payload[1]
        const refreshStatus = { ...state.refreshStatus }
        const refreshDate = { ...state.refreshDate }

        refreshGroups.forEach(group => {
          refreshStatus[group] = newStatus
          refreshDate[group] = newDate
        })
        return {
          ...state,
          refreshStatus,
          refreshDate,
        }
      },
      prepare: (
        status: string,
        refreshGroups: Array<refreshEndpointFieldEndpointType>
      ): { payload: [string, Array<refreshEndpointFieldEndpointType>] } => ({
        payload: [status, refreshGroups],
      }),
    },
  },
  extraReducers: builder => {
    builder.addCase(
      wsMsgReceived,
      (state, action: { payload: WsMsgRcvType | WsMsgRcvUnkownType }) => {
        const idx = stateIndexes.REFRESH_IDX_MSG_RECEIVED
        const refreshStatus = { ...state.refreshStatus }
        const refreshDate = { ...state.refreshDate }
        const { cmd, data } = action.payload
        let registrants = state.registrants
        let items = state.items
        let allItems = state.allItems
        let users = state.users
        let settings = state.settings

        if (cmd === 'update registrant') {
          const id = data.id
          // log('id index', id, index)
          if (
            id !== undefined &&
            data.reg &&
            (!objHasKey(registrants, id.toString()) || diff(data.reg, registrants[id]))
          ) {
            // log('existing registrant', registrants[id])
            log('receive update registrant', id)
            registrants = { ...state.registrants, [id]: data.reg } // shallow copy is enough
            refreshStatus[idx] = `updated registrant ${id}`
            refreshDate[idx] = getDate()
          } else {
            log('not updating registrant', id)
          }
        } else if (cmd === 'update user') {
          const id = data.id
          if (
            id !== undefined &&
            data.userData &&
            (!objHasKey(users, id.toString()) || diff(data.userData, users[id]))
          ) {
            users = { ...state.users, [id]: data.userData } // shallow copy is enough
            refreshStatus[idx] = `updated user ${id}`
            refreshDate[idx] = getDate()
          } else {
            log('not updating user', id)
          }
        } else if (cmd === 'update item') {
          const id = data.id
          if (
            id !== undefined &&
            data.item &&
            (!objHasKey(allItems, id.toString()) || diff(data.item, allItems[id]))
          ) {
            // updating both allItems and items
            allItems = { ...state.allItems, [id]: data.item } // shallow copy is enough
            items = { ...state.items, [id]: data.item } // shallow copy is enough
            refreshStatus[idx] = `updated item ${id}`
            refreshDate[idx] = getDate()
          } else {
            log('not updating item', id)
          }
        } else if (cmd === 'update settings') {
          if (data.settings && diff(settings, data.settings)) {
            settings = { ...state.settings, ...data.settings } // shallow copy is enough
            refreshStatus[idx] = 'updated settings'
            refreshDate[idx] = getDate()
            log('updated settings')

            // update items as settings may have changed
            items = Object.values(state.allItems)
              .filter(itemsSelectedIfEvent(settings))
              .reduce((acc, item) => {
                acc[item.slug] = item
                return acc
              }, {} as DbItemsType)
          } else {
            log('not updating settings')
          }
        } else if (cmd === 'version check') {
          if (data.version && diff(settings.app.version, data.version)) {
            const app = { ...settings.app }
            app.version = data.version
            settings = { ...state.settings, app }
            log('updated settings app version', app.version)
          }
        }

        return {
          ...state,
          registrants,
          items,
          allItems,
          users,
          settings,
          refreshStatus,
          refreshDate,
        }
      }
    )
  },
  selectors: {
    items: state => state.items,
    allItems: state => state.allItems,
    // validProductIdsForCheckin: state => state.validProductIdsForCheckin,
    registrants: state => state.registrants,
    users: state => state.users,
    settings: state => state.settings,
    refreshStatus: state => state.refreshStatus,
    refreshDate: state => state.refreshDate,
    errorData: state => state.errorData,
    searchOrderResults: state => state.searchOrderResults,
  },
})

export type RefreshActionTypes = SliceActions<typeof refreshSlice.actions>

export const selectors = refreshSlice.selectors
export default refreshSlice.reducer

export const {
  refreshRequest,
  refreshCancel,
  refreshFulfilled,
  refreshRejected,
  refreshUpdateInfo,
} = refreshSlice.actions
