import React, { useState, Suspense, lazy } from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'
import debug from 'debug'
import { Icon, Sidebar, Menu, Button, Modal } from 'semantic-ui-react'
import ScrollUp from 'react-scroll-up'
import PageLoader from 'AppSrc/components/pageloader'
import { history, useAppSelector, useAppDispatch } from 'AppSrc/store'
import WebSocketIndicator from 'AppSrc/websocket/component'
import { selectors as authSelectors, authSignOutRequest } from 'AppSrc/auth/reducer'
import Login from 'AppSrc/auth/component'
import { selectors as refreshSelectors } from 'AppSrc/refresh/reducer'
import TopMenu from 'AppSrc/components/topmenu/component'
import Lookup from 'AppSrc/components/lookup'
import Stats from 'AppSrc/components/stats'

import './style.css'

const Items = lazy(() => import('../items'))
const Registrants = lazy(() => import('../registrants'))
const Settings = lazy(() => import('../settings'))
const NotFound = lazy(() => import('../notfound'))

debug.enable('app:*')
// const log = debug('app:log')
// const info = debug('app:info')
// const error = debug('app:error')

let currentAppVersion: settingsType['app']['version'] // stored current app version

const App = () => {
  const dispatch = useAppDispatch()

  const authenticated = useAppSelector(state => authSelectors.authenticated(state))
  const settings = useAppSelector(state => refreshSelectors.settings(state))

  const [sidebarVisible, setSidebarVisible] = useState(false)

  const location = useLocation()

  if (!authenticated) {
    return <Login />
  }

  const isStatsPage = !!location.pathname.match(/\/stats\/?/)

  const userInfo = JSON.parse(localStorage.getItem('userInfo') || 'null')
  const isUserCheckinAppTeachers = userInfo?.name.toLowerCase() === 'checkin_app_teachers'

  if (isUserCheckinAppTeachers && !isStatsPage) {
    window.location.replace('/stats')
    return null
  }

  const hideSideBarMenu = isStatsPage && isUserCheckinAppTeachers

  const sidebarToggleVisibility = () => {
    setSidebarVisible(!sidebarVisible)
  }

  const itemsName = 'Items'
  const itemsNameLc = itemsName.toLowerCase()

  if (!currentAppVersion && settings.app?.version) currentAppVersion = settings.app.version

  const openNewVersionModal =
    !!currentAppVersion && !!settings.app?.version && currentAppVersion !== settings.app.version

  return (
    <div className="app">
      <Modal open={openNewVersionModal} closeOnDimmerClick={false} basic className="refresh-modal">
        <Modal.Content>
          A new version of the app is available
          <Button
            onClick={() => window.location.reload()}
            basic
            inverted
            size="large"
            className="tertiary refresh-button"
          >
            REFRESH
          </Button>
        </Modal.Content>
      </Modal>
      <ScrollUp showUnder={160} style={{ bottom: 80, zIndex: 10 }}>
        <Icon
          size="huge"
          name="arrow circle up"
          color="teal"
          style={{ margin: '0', padding: '0', width: '1em' }}
        />
      </ScrollUp>
      <Sidebar.Pushable className="scrollArea">
        {hideSideBarMenu ? null : (
          <Sidebar
            as={Menu}
            fixed="left"
            size="massive"
            animation="overlay"
            width="thin"
            icon="labeled"
            visible={sidebarVisible}
            vertical
            inverted
            color="teal"
          >
            <Menu.Item
              name="registrants"
              onClick={() => {
                sidebarToggleVisibility()
                history?.push('/registrants')
              }}
            >
              <Icon name="group" />
              Registrants
            </Menu.Item>
            <Menu.Item
              name={itemsNameLc}
              onClick={() => {
                sidebarToggleVisibility()
                history?.push(`/${itemsNameLc}`)
              }}
            >
              <Icon name={itemsNameLc.match(/class/) ? 'vcard' : 'list alternate'} />
              {itemsName}
            </Menu.Item>
            <Menu.Item
              name="register"
              onClick={() => {
                sidebarToggleVisibility()
                history?.push('/register')
              }}
            >
              <Icon name="add user" />
              Register
            </Menu.Item>
            <Menu.Item
              name="look up"
              onClick={() => {
                sidebarToggleVisibility()
                history?.push('/lookup')
              }}
            >
              <Icon name="search" />
              Look up
            </Menu.Item>
            <Menu.Item
              name="stats"
              onClick={() => {
                sidebarToggleVisibility()
                history?.push('/stats')
              }}
            >
              <Icon name="line graph" />
              Stats
            </Menu.Item>
            <Menu.Item
              name="settings"
              onClick={() => {
                sidebarToggleVisibility()
                history?.push('/settings')
              }}
            >
              <Icon name="settings" />
              Settings
            </Menu.Item>
            <Menu.Item
              name="logout"
              onClick={() => {
                sidebarToggleVisibility()
                history?.push('/')
                dispatch(authSignOutRequest())
              }}
            >
              <Icon name="log out" />
              Log out
            </Menu.Item>
          </Sidebar>
        )}

        <Sidebar.Pusher
          dimmed={sidebarVisible}
          onClick={() => {
            sidebarVisible && sidebarToggleVisibility()
          }}
        >
          <TopMenu />
          {hideSideBarMenu ? null : (
            <Button
              attached="right"
              color="teal"
              size="massive"
              onClick={sidebarToggleVisibility}
              className="sidebar-menu-icon"
              icon={<Icon name="sidebar" size="large" />}
            />
          )}
          <WebSocketIndicator />
          <main className="custom-loader">
            <Suspense fallback={<PageLoader />}>
              <Routes>
                <Route path="/" element={<Registrants mode="view" />} />
                <Route path={`/${itemsNameLc}`} element={<Items />} />
                <Route path="/settings" element={<Settings />} />
                <Route path={`/${itemsNameLc}/:itemId`} element={<Registrants mode="view" />} />
                <Route path="/registrants" element={<Registrants mode="view" />} />
                <Route path="/registrants/:registrantId" element={<Registrants mode="edit" />} />
                <Route path="/register" element={<Registrants mode="edit" />} key="register" />
                <Route path="/lookup" element={<Lookup />} key="lookup" />
                <Route path="/stats" element={<Stats />} key="stats" />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </Suspense>
          </main>
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    </div>
  )
}

export default App
