import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Position, UiPayload } from './types'
import { store } from 'AppSrc/store'

export const key = 'ui'

const initialState: { sidebarVisible: boolean; scrollPosition: Record<string, Position> } = {
  sidebarVisible: false,
  scrollPosition: {},
}

const uiSlice = createSlice({
  name: key,
  initialState,
  reducers: {
    uiSidebarToggleVisibility: (state, _action: PayloadAction) => ({
      ...state,
      sidebarVisible: !state.sidebarVisible,
    }),
    uiSignedOut: (state, _action: PayloadAction) => ({
      ...state,
      sidebarVisible: false,
    }),
    uiSaveScrollPosition: {
      reducer: (state, action: { payload: UiPayload }) => {
        const payload = action.payload
        return {
          ...state,
          scrollPosition: { ...state.scrollPosition, [payload[0]]: payload[1] },
        }
      },
      prepare: (pathname: Location['pathname'], position: Position): { payload: UiPayload } => ({
        payload: [pathname, position],
      }),
    },
    uiRestoreScrollPosition: state => state,
    uiRestoreScrollWrongPosition: state => state,
  },
  selectors: {
    sidebarVisible: state => state.sidebarVisible,
    scrollPosition: state => state.scrollPosition,
  },
})

export const restoreScrollPosition = (pathname: Location['pathname'], position: Position) => {
  if (window.location.pathname === pathname) {
    const { x, y } = position || { x: 0, y: 0 }
    // log('scrollTo', x, y)
    window.scrollTo({ top: y, left: x, behavior: 'auto' })
    return uiRestoreScrollPosition()
  }

  return uiRestoreScrollWrongPosition()
}

export type uiActionTypes = SliceActions<typeof uiSlice.actions>

export const actions = uiSlice.actions
export const selectors = uiSlice.selectors
export default uiSlice.reducer

export const {
  uiSidebarToggleVisibility,
  uiSaveScrollPosition,
  uiRestoreScrollPosition,
  uiRestoreScrollWrongPosition,
  uiSignedOut,
} = uiSlice.actions
