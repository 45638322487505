import React from 'react'
import { Label, Icon, Popup } from 'semantic-ui-react'
import { selectors as wsSelectors } from './reducer'
import { useAppSelector } from 'AppSrc/store'
import './style.css'

const WebSocketIndicator = () => {
  const wsConnected = useAppSelector(state => wsSelectors.wsConnected(state))

  return (
    <Popup
      trigger={
        <Label
          {...{
            as: Icon,
            attached: 'top right',
            size: 'large',
            circular: true,
            inverted: true,
            color: wsConnected ? 'teal' : 'red',
            name: 'rss',
            className: 'ws-indicator',
          }}
        />
      }
      offset={[-3, -10]}
      hoverable
      content={wsConnected ? 'server socket connected' : 'server socket disconnected'}
      position="top right"
      size="large"
      inverted
    />
  )
}

export default WebSocketIndicator
