import { anyToString } from 'AppSrc/store/helpers'
import debug from 'debug'
import { getDate } from './helpers'
import { createSlice } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'

debug.enable('order/reducer:*')
// const log = debug('order/reducer:log')
// const info = debug('order/reducer:info')
// const error = debug('order/reducer:error')

export const key = 'order'

const initialState: {
  orderUpdateStatus: { [id: number | string]: string }
  orderUpdateDate: { [id: number | string]: string }
  errorData: { [id: number | string]: string }
} = {
  orderUpdateStatus: { 999999: 'new' },
  orderUpdateDate: {},
  errorData: {},
}

const orderSlice = createSlice({
  name: key,
  initialState,
  reducers: {
    orderUpdateRequest: {
      reducer: (state, action: { payload: [string, number | string, object] }) => {
        const idx = action.payload[0]
        return {
          ...state,
          orderUpdateStatus: { ...state.orderUpdateStatus, [idx]: 'processing order update' },
          orderUpdateDate: { ...state.orderUpdateDate, [idx]: getDate() },
        }
      },
      prepare: (
        idx: string,
        regId: number | string,
        orderUpdateData: object
      ): { payload: [string, number | string, object] } => ({
        payload: [idx, regId, orderUpdateData],
      }),
    },
    orderUpdateFulfilled: {
      reducer: (state, action: { payload: [string] }) => {
        const idx = action.payload[0]
        return {
          ...state,
          orderUpdateStatus: { ...state.orderUpdateStatus, [idx]: 'order update succeeded' },
          orderUpdateDate: { ...state.orderUpdateDate, [idx]: getDate() },
        }
      },
      prepare: (idx: string): { payload: [string] } => ({ payload: [idx] }),
    },
    orderUpdateRejected: {
      reducer: (state, action: { payload: [string, string | AxiosError] }) => {
        const idx = action.payload[0]
        const err = action.payload[1]
        return {
          ...state,
          orderUpdateStatus: { ...state.orderUpdateStatus, [idx]: 'order update rejected' },
          orderUpdateDate: { ...state.orderUpdateDate, [idx]: getDate() },
          errorData: { ...state.errorData, [idx]: anyToString(err) },
        }
      },
      prepare: (
        idx: string,
        err: string | AxiosError
      ): { payload: [string, string | AxiosError] } => ({
        payload: [idx, err],
      }),
    },
  },
  selectors: {
    orderUpdateStatus: state => state.orderUpdateStatus,
    orderUpdateDate: state => state.orderUpdateDate,
    errorData: state => state.errorData,
  },
})

export type OrderActionTypes = SliceActions<typeof orderSlice.actions>

export const selectors = orderSlice.selectors
export default orderSlice.reducer

export const { orderUpdateRequest, orderUpdateFulfilled, orderUpdateRejected } = orderSlice.actions
