import { store } from 'AppSrc/store'
import debug from 'debug'
import { createSlice } from '@reduxjs/toolkit'
import { focusSearchInputField } from './helpers'
import { ChangeEvent } from 'react'

debug.enable('search/reducer:*')
// const log = debug('search/reducer:log')
// const info = debug('search/reducer:info')
// const error = debug('search/reducer:error')

export const key = 'search'

const initialState: {
  searchString: string
  activeSearchString: string
  searchOrderTerm: string
  searchOrderRefreshIndex: `#${string}` | undefined
} = {
  searchString: '',
  activeSearchString: '',
  searchOrderTerm: '',
  searchOrderRefreshIndex: undefined,
}

const searchSlice = createSlice({
  name: key,
  initialState,
  reducers: {
    searchUpdateSearchString: {
      reducer: (state, action: { payload: [string] }) => {
        return {
          ...state,
          searchString: action.payload[0],
        }
      },
      prepare: (
        _ev: ChangeEvent<HTMLInputElement> | null,
        { value }: { value: string }
      ): { payload: [string] } => {
        store.dispatch(searchUpdateActiveSearchString(value))
        focusSearchInputField()
        return { payload: [value] } // search string
      },
    },
    searchUpdateActiveSearchString: {
      reducer: (state, action: { payload: [string] }) => {
        return {
          ...state,
          activeSearchString: action.payload[0],
        }
      },
      prepare: (value: string): { payload: [string] } => ({ payload: [value] }), // active search string
    },
    searchUpdateSearchOrderTerm: {
      reducer: (state, action: { payload: [string] }) => {
        return {
          ...state,
          searchOrderTerm: action.payload[0],
        }
      },
      prepare: (
        _ev: ChangeEvent<HTMLInputElement> | KeyboardEvent | null,
        { value }: { value: string }
      ): { payload: [string] } => ({ payload: [value] }), // search order term
    },
    searchUpdateSearchOrderRefreshIndex: {
      reducer: (state, action: { payload: [`#${string}` | undefined] }) => {
        return {
          ...state,
          searchOrderRefreshIndex: action.payload[0],
        }
      },
      prepare: (value: `#${string}` | undefined): { payload: [`#${string}` | undefined] } => ({
        payload: [value], // search order refreshindex
      }),
    },
  },
  selectors: {
    searchString: state => state.searchString,
    activeSearchString: state => state.activeSearchString,
    searchOrderTerm: state => state.searchOrderTerm,
    searchOrderRefreshIndex: state => state.searchOrderRefreshIndex,
  },
})

export type SearchActionTypes = SliceActions<typeof searchSlice.actions>

export const actions = searchSlice.actions
export const selectors = searchSlice.selectors
export default searchSlice.reducer

export const {
  searchUpdateSearchString,
  searchUpdateActiveSearchString,
  searchUpdateSearchOrderRefreshIndex,
  searchUpdateSearchOrderTerm,
} = searchSlice.actions
