import React from 'react'
import debug from 'debug'
import { Button } from 'semantic-ui-react'
import { selectors as refreshSelectors } from 'AppSrc/refresh/reducer'
import { selectors as checkinSelectors } from './reducer'
import { processCheckinRequest, getCheckinInfo } from './helpers'
import { useAppDispatch, useAppSelector } from 'AppSrc/store'
import './style.css'

debug.enable('checkin/component:*')
// const log = debug('checkin/component:log')
// const info = debug('checkin/component:info')
// const error = debug('checkin/component:error')

type Props = {
  name: string
  regId: number | string
  checkins: [CheckinsType]
  checkinItems: [RegistrantItemsType]
}

const Checkin = ({ name, regId, checkins, checkinItems }: Props) => {
  const dispatch = useAppDispatch()
  const checkinStatus = useAppSelector(state => checkinSelectors.checkinStatus(state))
  // const checkinDate = useAppSelector(state => checkinSelectors.checkinDate(state)),
  // const errorData = useAppSelector(state => checkinSelectors.errorData(state)),
  const settings = useAppSelector(state => refreshSelectors.settings(state))

  const { idx, content, checkedIn } = getCheckinInfo({
    name,
    regId,
    checkins,
    checkinItems,
  })

  return (
    <Button
      {...{
        basic: checkedIn,
        color: checkedIn ? 'grey' : 'green',
        size: checkedIn ? 'huge' : 'massive',
        compact: true,
        onClick: () =>
          processCheckinRequest({
            name,
            regId,
            checkins,
            checkinItems,
            dispatch,
            settings,
          }),
        content,
        loading: !!(checkinStatus[idx] || '').match(/ing/), // FIXME /ing/
        className: 'check-in-button',
      }}
    />
  )
}

export default Checkin
