import React from 'react'
import { type IntersectionOptions, useInView } from 'react-intersection-observer'

type Props = {
  children: React.ReactNode
  style?: React.CSSProperties
} & IntersectionOptions

const Visibility = ({ children, style, ...options }: Props) => {
  const { ref, inView, entry } = useInView(options)

  return (
    <div ref={ref} style={style}>
      {children}
    </div>
  )
}

export default Visibility
