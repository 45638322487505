import React from 'react'
import { Loader } from 'semantic-ui-react'

type Props = {
  message?: string
}

const PageLoader = ({ message = 'Loading...' }: Props) => (
  <div style={{ width: '100%', height: '100vh' }}>
    <Loader
      active
      inline="centered"
      size="large"
      style={{
        zIndex: 3,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
      }}
    >
      {message}
    </Loader>
  </div>
)
export default PageLoader
