import React from 'react'
import debug from 'debug'
import { List, Accordion, Icon, Modal } from 'semantic-ui-react'
import { selectors as searchSelectors } from 'AppSrc/search/reducer'
import { getRegistrantOrderIdsByName } from 'AppSrc/components/registrants/helpers'
import { useAppSelector, useAppDispatch } from 'AppSrc/store'
import './style.css'
import { orderUpdateRequest } from 'AppSrc/order/reducer'

debug.enable('ordernotes/component:*')
// const log = debug('ordernotes/component:log')
// const info = debug('ordernotes/component:info')
// const error = debug('ordernotes/component:error')

type Props = {
  reg: RegistrantType
  name: string
  orderId?: string
  openAccordion?: boolean
  className: string
}

const OrderNotes = ({
  reg,
  name,
  orderId: sOrderId = '',
  openAccordion = false,
  className,
}: Props) => {
  const dispatch = useAppDispatch()

  const activeSearchString = useAppSelector(state => searchSelectors.activeSearchString(state))

  const onClick = (orderId: number, noteId: number) => {
    const idx = `deletenote-${orderId}-${noteId}`
    // log('orderUpdateRequest', idx, reg.id, { orderId, order_note: { id: noteId } })
    dispatch(
      orderUpdateRequest(idx, reg.id as string, {
        orderId,
        order_note: { id: noteId },
      })
    )
  }

  const orderIds = Object.keys(reg.order_notes || [])
    .map(orderId => Number(orderId))
    .filter(orderId => !sOrderId || orderId === Number(sOrderId))
    .filter(
      orderId =>
        getRegistrantOrderIdsByName(reg.items, reg, name).indexOf(orderId.toString()) !== -1
    )
    .sort((a, b) => a - b)

  return (
    <div className={className}>
      {orderIds.map(orderId => (
        <Accordion
          key={`accordion-notes-${orderId}`}
          style={{ lineHeight: '1.4em' }}
          defaultActiveIndex={openAccordion || activeSearchString ? [0] : []}
          exclusive={false}
          panels={[
            {
              key: `order-notes-${orderId}`,
              title: `ORDER ${orderIds.length > 1 ? `#${orderId} ` : ''}NOTES`,
              content: {
                content: (
                  <List>
                    {Object.keys(reg.order_notes[orderId])
                      .map(noteId => Number(noteId))
                      .sort((a, b) => a - b)
                      .map(noteId => (
                        <List.Item key={noteId}>
                          <Modal
                            trigger={
                              <Icon
                                name={noteId >= 0 ? 'close' : 'circle'}
                                size={noteId >= 0 ? 'small' : 'mini'}
                              />
                            }
                            header="Delete Note"
                            content={
                              <div className="content">
                                Do you want to delete this note?
                                <div style={{ marginTop: '1em' }}>
                                  {reg.order_notes[orderId][noteId]}
                                </div>
                              </div>
                            }
                            actions={[
                              'No',
                              {
                                key: 'delete-note',
                                content: 'Yes, delete it',
                                negative: true,
                                onClick: () => onClick(Number(orderId), Number(noteId)),
                              },
                            ]}
                          />
                          <List.Content key={noteId}>
                            {reg.order_notes[orderId][noteId]}
                          </List.Content>
                        </List.Item>
                      ))}
                  </List>
                ),
              },
            },
          ]}
          className="order-notes-accordion"
        />
      ))}
    </div>
  )
}

export default OrderNotes
