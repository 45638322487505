import React from 'react'
import debug from 'debug'
import { Button, ButtonProps, Icon, Popup } from 'semantic-ui-react'
import { selectors as refreshSelectors } from 'AppSrc/refresh/reducer'
import { metaDataRequest, selectors as metaDataSelectors } from './reducer'
import { MetaDataPayloadDataType } from './types'
import { getVaccinationBeforeDate, getMetaDataIdx, getDate } from './helpers'
import { objHasKey } from 'AppSrc/components/registrants/helpers'
import { useAppDispatch, useAppSelector } from 'AppSrc/store'
import './style.css'

debug.enable('metadatavaccination/component:*')
const log = debug('metadatavaccination/component:log')
// const info = debug('metadatavaccination/component:info')
// const error = debug('metadatavaccination/component:error')

type Props = {
  regId: string
  orderIds: string[]
  regOrderExtra: { [orderId: string]: wcOrderExtraType }
}

const metaDataType = 'vaccination'

const MetaDataVaccination = ({ regId, orderIds, regOrderExtra }: Props) => {
  const dispatch = useAppDispatch()

  const metaDataStatus = useAppSelector(state => metaDataSelectors.metaDataStatus(state))
  // const metaDataDate = useAppSelector(state => metaDataSelectors.metaDataDate(state)),
  // const errorData = useAppSelector(state => metaDataSelectors.errorData(state)),
  const users = useAppSelector(state => refreshSelectors.users(state))

  if (!regId || regId === '' || regId === '0') {
    // we should only get here when registering a new user
    return null
  }

  const user = !isNaN(Number(regId)) && objHasKey(users, regId) ? users[regId] : null
  // log('user', user)

  // Get order verification information
  const ordersNeedVerification = orderIds.reduce(
    (acc, orderId) => acc || !regOrderExtra[orderId]?.vaccination_verified,
    false
  )

  const vaccinationNeedsVerification: boolean = user
    ? user?.vaccination_needs_verification || false // we ignore order verification for users
    : ordersNeedVerification

  const onClickFunc = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    data: ButtonProps
  ) => {
    const idx = getMetaDataIdx(regId, metaDataType)

    const vaccinationVerifiedData = {
      vaccination_verified: vaccinationNeedsVerification,
      vaccination_verified_date: vaccinationNeedsVerification ? getDate() : '',
    } satisfies wcOrderExtraType

    const payloadData = {
      ...(user && {
        user: {
          userId: Number(regId),
          meta: vaccinationVerifiedData,
        },
      }),
      orders: {
        regId,
        orderIds,
        orderExtra: vaccinationVerifiedData,
      },
    } satisfies MetaDataPayloadDataType

    log('metaDataRequest', idx, payloadData)
    dispatch(metaDataRequest(idx, payloadData))
  }

  const idx = getMetaDataIdx(regId, metaDataType)
  const metaDataUpdateStatus = metaDataStatus[idx]
  const loading = !!(metaDataUpdateStatus || '').match(/ing/) // FIXME /ing/

  const components = []

  if (!vaccinationNeedsVerification) {
    // vaccination is already verified, display syringe icon, click means unverifying vaccination
    components.push(
      <Popup
        key="syringe"
        hoverable
        flowing
        on={['click']}
        trigger={
          <Icon
            size="large"
            name="syringe"
            color={user ? 'green' : 'grey'}
            fitted
            loading={loading}
            className="icon-button syringe-icon"
          />
        }
        header="Vaccinated"
        content={
          <div>
            <div>Do you want to remove their vaccination status?</div>
            <div style={{ textAlign: 'center', marginTop: '.5em' }}>
              <Button size="large" onClick={onClickFunc} color="red" basic content="Yes, Remove" />
            </div>
          </div>
        }
        offset={[-12, 0]}
        className="syringe-popup"
      />
    )

    return <div className="metadata">{components}</div>
  }

  // vaccination needs verification, display button to verify it
  components.push(
    <Button
      key={`verify-${metaDataType}`}
      {...{
        basic: true,
        color: 'red',
        size: 'large',
        compact: true,
        onClick: onClickFunc,
        content: `Verify ${metaDataType} is before ${getVaccinationBeforeDate()}`,
        loading,
      }}
    />
  )

  return <div className="metadata">{components}</div>
}

export default MetaDataVaccination
