import { Dependency, RootState } from 'AppSrc/store'
import { createLogic } from 'redux-logic'
import debug from 'debug'
import { SearchMeta, SearchPayload } from './types'
import { SearchActionTypes, searchUpdateActiveSearchString } from './reducer'

debug.enable('search/logic:*')
// const log = debug('search/logic:log')
// const info = debug('search/logic:info')
// const error = debug('search/logic:error')

export const searchLogic = createLogic<
  RootState, // State
  SearchPayload, // Payload
  SearchMeta, // Meta
  Dependency, // Dependency
  SearchActionTypes // Context
>({
  type: searchUpdateActiveSearchString.type,
  debounce: 400,
  latest: true, // take latest only

  // validate({ getState, action }, allow, reject) {
  //   if (action.payload) {
  //     allow(action)
  //   } else { // empty request, silently reject
  //     reject()
  //   }
  // },

  process({ action }, dispatch, done) {
    const value: string = action.payload[0]
    dispatch(searchUpdateActiveSearchString(value))
    done()
  },
})

export default searchLogic
