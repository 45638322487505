import React from 'react'
import { Grid, Menu } from 'semantic-ui-react'
import { selectors as topMenuSelectors } from './reducer'
import { useAppSelector } from 'AppSrc/store'
import './style.css'

const TopMenu = () => {
  const menuItem = useAppSelector(state => topMenuSelectors.content(state)) as JSX.Element | null

  return (
    <Menu color="teal" inverted fixed="top" size="massive" widths={1} className="top-menu">
      <Grid container>
        <Grid.Row>
          <Grid.Column computer={4} tablet={3} mobile={3} />
          <Grid.Column computer={8} tablet={10} mobile={10}>
            <Menu.Item>{menuItem}</Menu.Item>
          </Grid.Column>
          <Grid.Column computer={4} tablet={3} mobile={3} />
        </Grid.Row>
      </Grid>
    </Menu>
  )
}

export default TopMenu
