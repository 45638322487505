import debug from 'debug'
import { TopMenuPayload } from './types'
import { createSlice } from '@reduxjs/toolkit'

debug.enable('topmenu/reducer:*')
// const log = debug('topmenu/reducer:log')
// const info = debug('topmenu/reducer:info')
// const error = debug('topmenu/reducer:error')

export const key = 'topmenu'

const initialState: {
  content: TopMenuPayload | null
} = {
  content: null,
}

const topmenuSlice = createSlice({
  name: key,
  initialState,
  reducers: {
    updateTopMenuContent: {
      reducer: (state, action: { payload: TopMenuPayload }) => ({
        ...state,
        content: action.payload,
      }),
      prepare: (content: TopMenuPayload): { payload: TopMenuPayload } => ({ payload: content }),
    },
  },
  selectors: {
    content: state => state.content,
  },
})

export type TopMenuActionTypes = SliceActions<typeof topmenuSlice.actions>

export const actions = topmenuSlice.actions
export const selectors = topmenuSlice.selectors
export default topmenuSlice.reducer

export const { updateTopMenuContent } = topmenuSlice.actions
